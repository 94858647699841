import SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';
import {getAuthorization} from "@plugins/MercuryBaseLib";
import {shortUuid} from "@plugins/MercuryBaseUtils";

const _defaultStompEndpoint = process.env.VUE_APP_API_HOST + process.env.VUE_APP_WEB_SOCKET_BASE_PATH

let _socket = null;
let _stompClient = null;
let _debugFlag = false;
let _beforeConnectedCallbacks = [];
let _subscribeMap = {};

export function setDebugFlag(debugFlag) {
    _debugFlag = debugFlag;
}

window.__setWebsocketDebugFlag = setDebugFlag

const _assignedHeaders = function (headers) {
    return Object.assign(headers || {}, {'Authorization': getAuthorization(), id: `sub-${shortUuid()}`});
}

const _subscribeFunc = function (subscribeType, destination, callback, headers) {

    unsubscribe(subscribeType);

    const assignedHeaders = _assignedHeaders(headers);
    const assignedCallback = function (message) {
        const assignMessage = {
            topicName: message.headers.destination,
            eventBody: JSON.parse(message.body)
        };

        callback(assignMessage);

    };

    _subscribeMap[subscribeType] = {
        subscribe: _stompClient.subscribe(destination, assignedCallback, assignedHeaders),
        destination: destination,
        callback: callback,
        headers: assignedHeaders
    };
};

export function connect(headers, callbacks, endpoint, isReconnect) {
    const socket = new SockJS(endpoint || _defaultStompEndpoint);
    const stompClient = Stomp.over(socket);//Stomp.overWS
    const assignedHeaders = _assignedHeaders(headers);
    stompClient.connect(assignedHeaders, function (frame) {

        if (isReconnect) {
            stompClient.debug('isReconnect success');
            for (const subscribeType in _subscribeMap) {
                if (Object.prototype.hasOwnProperty.call(_subscribeMap, subscribeType)) {
                    const subscribeInfo = _subscribeMap[subscribeType];
                    _subscribeFunc(subscribeType, subscribeInfo.destination, subscribeInfo.callback, subscribeInfo.headers);
                }
            }
        }

        const remainingCallbacks = _beforeConnectedCallbacks.splice(0, _beforeConnectedCallbacks.length);
        for (let i = 0, ic = remainingCallbacks.length; i < ic; i++) {
            remainingCallbacks[i]();
        }

        if (typeof callbacks && typeof callbacks['connect'] === 'function') {
            callbacks['connect'](frame);
        } else {
            stompClient.debug('Connected: ' + frame);
        }
    }, function (error) {
        if (typeof callbacks && typeof callbacks['error'] === 'function') {
            callbacks['error'](error);
        } else {
            stompClient.debug('ws connect fail error', error);
        }
    });

    socket.onclose = function () {
        stompClient.disconnect();
        if (typeof callbacks && typeof callbacks['onclose'] === 'function') {
            stompClient.debug('websocket closed. trying to reconnect websocket automatically');
            callbacks['onclose']();
        }

        setTimeout(function () {
            connect(headers, callbacks, endpoint, true);
        }, 3000);
    };

    _socket = socket;
    _stompClient = stompClient;
    _stompClient.debug = function (str) {
        if (_debugFlag) {
            console.log(str);
        }
    };
}

export function send(subscribeType, headers = {}, body = {}) {
    if (_stompClient && _stompClient.connected) {
        _stompClient.send(subscribeType, _assignedHeaders(headers), JSON.stringify(body));
    }
}

export function subscribe(subscribeType, destination, callback, headers) {
    if (_stompClient && _stompClient.connected) {
        _subscribeFunc(subscribeType, destination, callback, headers);
    } else {
        _beforeConnectedCallbacks.push(function () {
            _subscribeFunc(subscribeType, destination, callback, headers);
        });
    }
}

export function unsubscribe(subscribeType) {
    try {
        const subscribeObj = _subscribeMap[subscribeType];
        if (subscribeObj && subscribeObj.subscribe && subscribeObj.subscribe.unsubscribe) {
            subscribeObj.subscribe.unsubscribe();
        }
    } finally {
        delete _subscribeMap[subscribeType];
    }
}
