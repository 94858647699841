/**
 * util은 해당 프로젝트가 아니여도 모든 프로젝트에서 사용할 수 있도록 공통 스크립트만 작성한다.
 *     - 화면내의 dom을 처리 하는 작업을 하지 않도록 한다.   (dom 조작은 ui를 사용)
 *     - util은 단독으로도 사용할 수있도록 선별하여 작성한다. (ui, lib, vue 등을 호출하지 말자)
 *
 * [문서화를 위한 주석 규칙은](http://yui.github.io/yuidoc/syntax/index.html)
 * */

import moment from 'moment';
import $ from 'jquery';

export const KEYCODE = {
    ENTER: 13,
    ESCAPE: 27,
    ARROW_LEFT: 37,
    ARROW_UP: 38,
    ARROW_RIGHT: 39,
    ARROW_DOWN: 40,
    QUESTION: 191,
    CTRL: 17,
    ALT: 18,
    m: 77,
    k: 75,
};

export const COOKIE_NAME_LOCALE = 'lang'

/**
 * path에 포함된 pathVariables를 pathParams를 통해 binding한다.
 *
 * mercury.base.util.bindPath('/base/{category1}/list/{category2}',{category1:'board',category2:'notice'});
 * =>  /base/board/list/notice
 *
 * @param {String} pathExpression 경로로 pathVariables를 포함할 수도 있다.
 * @param {Object} pathParams binding할 object
 *
 * @return pathVariable이 모두 바인딩된 경로
 * */

export const bindPath = (pathExpression, pathParams) => {
    let path = pathExpression;
    if (!path.match(/^\//)) {
        path = '/' + path;
    }

    if (pathParams) {
        path = path.replace(/{([\w-]+)}/g, function (fullMatch, key) {
            if (pathParams.hasOwnProperty(key)) {
                return pathParams[key];
            }
        });
    }

    return path;
}

/**
 * 해당 문자열이 값을 가지고 있는지 체크 한다. null, undefined, 공백 을 체크한다.
 * @param {String} str 문자열
 *
 * @return {Boolean} 값이 있는지 여부
 */

export const hasText = (str) => {
    return str !== undefined && str !== null && str.trim() !== '';
}

/**
 * camelCase를 snakeCase로 변경한다
 * TypeOfData.AlphaBeta => type_of_data_alpha_beta
 *
 * @param {String} s camelCase 문자열
 *
 * @returns {String} snake case로 변경된 문자열
 * */

export const snakeCase = (s) => {
    return s.replace(/\.?([A-Z]+)/g, function (x, y) {
        return "_" + y.toLowerCase()
    }).replace(/^_/, "")
}

/**
 * snakeCase를 camelCase로 변경한다
 * type_of_data_alpha_beta => TypeOfData.AlphaBeta
 *
 * @param {String} s camelCase 문자열
 *
 * @returns {String} snake case로 변경된 문자열
 * */
export const snakeToCamelCase = (s) => {
    return s.toLowerCase().replace(/([-_][a-z])/g, group =>
        group
            .toUpperCase()
            .replace('-', '')
            .replace('_', '')
    );
}

/**
 * 문자열에 포함된 html tag를 제거 한다.
 *
 * @param {String} s html이 포함된 문자열
 *
 * @returns {String} html이 제거된 문자열
 * */

export const stripHtml = (s) => {
    return s.replace(/<[^>]*>?/gm, '')
}

/**
 * UUID 생성
 * e12dddb3-3265-461f-823a-d738d0f742be
 *
 * @returns {String} javascript uuid
 * */

export const uuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

/**
 * shourt UUID 생성
 * koinnf
 *
 * @returns {String} javascript short uuid
 * */

export const shortUuid = () => {
    // I generate the UID from two parts here
    // to ensure the random number provide enough bits.
    let firstPart = (Math.random() * 46656) | 0;
    let secondPart = (Math.random() * 46656) | 0;
    firstPart = ("000" + firstPart.toString(36)).slice(-3);
    secondPart = ("000" + secondPart.toString(36)).slice(-3);
    return firstPart + secondPart;
}

/**
 * @description 파일 사이즈를 읽기 좋게 표시해준다.
 *
 * 100 => 100 B
 * 10000 => 9.77 KB
 * 10000000 => 9.54 MB
 *
 * @param {Number} size 파일사이즈
 * @returns {String} 포맷된 파일 사이즈
 * */

export const fileSize = (size) => {
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

/**
 * @description     저장된 브라우저 cookie중 name에 해당하는 값을 가져온다.
 * @param  {string } key        가져올 쿠키의 key값
 * @return {string }            key에 해당하는 value값
 */

export const getCookie = (key) => {
    const value = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return value ? value[2] : null;
}

/**
 * @description    브라우저 쿠키 세팅한다. value가 null 인경우는 쿠키를 삭제한다.
 * @param {string } key        저장할 쿠키 이름
 * @param {string||null } value        저장할 쿠키 값, null인경우 해당키 삭제
 * @param {Number } expireDays    만료일자
 */

export const setCookie = (key, value, expireDays = 7) => {
    let date = new Date();
    if(expireDays instanceof Date) {
        date = expireDays
    }else {
        if (expireDays === undefined) {
            expireDays = 7;
        }
        date.setTime(date.getTime() + expireDays * 60 * 60 * 24 * 1000);
    }

    if (value !== null) {
        document.cookie = key + '=' + value + ';expires=' + date.toUTCString() + ';path=/';
    } else {
        document.cookie = key + "= " + "; expires=" + date.toUTCString() + "; path=/";
    }
}


export const setLocalStorage = (key, value) => {
    let saveValue = value;
    if (typeof value === 'object') {
        saveValue = JSON.stringify(value)
    }

    localStorage.setItem(key, saveValue);
}

export const getLocalStorage = (key) => {
    let restoreValue = localStorage.getItem(key)
    if (restoreValue !== null) {
        try {
            restoreValue = JSON.parse(restoreValue)
        } catch (e) {

        }
    }

    return restoreValue;
}

/**
 * @description    오늘 날짜 기준 특정 기간 추출(startDate, endDate)
 * @param {Number} startOffset 시작
 * @param {Number} endOffset 종료
 * @param {string} period day: '일', week: '주', month: '월', year: '년'
 */
export const getDateRange = (startOffset = 0, endOffset = 0, period = 'day') => {
    return [
        moment().subtract(startOffset, period).startOf(period),
        moment().subtract(endOffset, period).endOf(period),
    ];
}

export const getDateRangeFormat = (range) => {
    return moment(range['start']).format('YYYY-MM-DD') + ' ~ ' + moment(range['end']).format('YYYY-MM-DD');
}

/**
 * @description fetchData 등에서 range에 들어 있는 date 객체를 날짜 검색을 하기위한 값으로 변경 후 searchObject에 넣어준다.
 *
 * @param {Object} searchObject 검색용 서치 객체
 * @param {String} rangeKeyName range: { start: Date, end: Date} 형태에서 range
 * @param {String} startFieldName searchObject에 넣을 fieldName
 * @param {String} endFieldName true면 뒤, false면 앞
 *
 */
export const setDateRangeParam = (searchObject, rangeKeyName, startFieldName, endFieldName) => {
    const range = searchObject[rangeKeyName];
    if (range === undefined) {
        throw Error('[' + rangeKeyName + '] is not defined in searchObject');
    }

    searchObject[startFieldName] = moment(range['start']).format('YYYY-MM-DD 00:00:00');
    searchObject[endFieldName] = moment(range['end']).format('YYYY-MM-DD 23:59:59.999999');
}

/**
 * @description 전달된 value가 값을 가지고 있는지 체크 null, undefined, 공백인지를 체크 한다.
 *
 * @param {String} value 값
 * @returns {Boolean} 값이 있는지 여부
 */
export const hasValue = (value) => {
    return !(value === null || value === undefined || value === '');
}

/**
 * @description array의 앞, 뒤를 지정해서 items를 추가한다.
 * @param {Array} src 원본 array
 * @param {Array} items 추가할 array
 * @param {Boolean} isAppend true면 뒤, false면 앞
 * @returns {Array} 합쳐진 하나의 array
 */

export const concatArray = (src, items, isAppend) => {
    return isAppend ? src.concat(items) : items.concat(src);
}

/**
 * @description 전달된 object가 array인지 확인 한다.
 * @param {Object} obj array인지 확인할 Object
 * @returns {Boolean} array 인지 여부
 */

export const isArray = (obj) => {
    return Object.prototype.toString.call(obj) === "[object Array]"
}

/**
 * @description 전달된 object의 깊은 복사를 수행한다.
 * @param {Object} obj 대상 object
 * @returns {Object} 깊은 복사된 obj 객체
 */

export const deepCopy = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

/**
 * @description 전달된 object 를 변경하지 못하도록 불변하게 만든다..
 * @param {Object} obj 대상 object
 * @returns {Object} immutable object
 */
export const deepFreeze = (obj) => {
    Object.keys(obj).forEach(prop => {
        if (typeof obj[prop] === 'object' && !Object.isFrozen(obj[prop])) deepFreeze(obj[prop]);
    });
    return Object.freeze(obj);
}

/**
 * @description 숫자 세자리마다 콤마(,) 금액
 * @param {string} str 숫자형 스트링
 * @returns {string} 소수점 포함 금액 포맷팅된 문자열
 */

export const addComma = (str) => {
    return Number(str).toLocaleString('en').split(".")[0];
}

/**
 * @description obj 의 값을 queryString 형태로 변경한다. $.param 과 동일한 기능
 *
 * @param {Object} obj queryString 형태로 변경할 Object
 * @param {String} prefix key앞에 붙일 prefix
 * @return {String} a=1&b=1 형태의 쿼리 스트링
 * */

export const param = (obj, prefix) => {
    let str = [], p;
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            let k = prefix ? prefix + "[" + p + "]" : p;
            let v = obj[p];
            if (v === undefined) {
                v = '';
            }

            str.push((v !== null && typeof v === "object") ? param(v, k) : encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }
    return str.join("&");
}

/**
 * @description vuetify 의 v-data-table 사용 시 ajax전송에 필요한 queryString 정보를 만들때 사용한다.
 *
 * @param {Object} options v-data-table options.sync="options" 옵션과 연결된 options
 *      const {groupBy, groupDesc, itemsPerPage, multiSort, mustSort, page, sortBy, sortDesc} = options
 * @param {Object} data 해당 data-table에서 필요한 커스텀 데이터
 * @param {Array} excludeKeys data에서 제외시킬 필드이 름
 *      {custNo : 1}
 * */
export const dataTablesParam = (options, data, excludeKeys) => {
    const page = options.page ? options.page : 1;
    const size = options.itemsPerPage ? options.itemsPerPage : 5;
    const sortBy = options.sortBy ? options.sortBy : [];
    const sortDesc = options.sortDesc ? options.sortDesc : [];

    let query = ''; //JPA는 page가 0 base
    query += 'pageType=dataTables&page=' + (page - 1) + '&size=' + size;
    for (let i = 0, ic = sortBy.length; i < ic; i++) {
        let _sortDesc = sortDesc[i]
        if (_sortDesc === true) {
            _sortDesc = 'DESC';
        } else if (_sortDesc === false) {
            _sortDesc = 'ASC';
        }

        query += '&sort=' + sortBy[i] + ',' + (_sortDesc ? _sortDesc : 'ASC');
    }

    if (data) {
        query += '&' + $.param(data);
    }

    return query;
}

/**
 * @description vuetify 의 v-data-table 사용 시 options 정보를 리셋한다
 * @param {Object} options v-data-table options.sync="options" 옵션과 연결된 options
 * */
export const dataTablesReset = (options) => {
    Object.assign(options, {
        __ts: new Date().getTime(),
        page: 1,
        //sortBy: [],
        //sortDesc: [],
        //groupBy: [],
        groupDesc: []
    });
}

/**
 * @description roles 안에 arr에 해당하는 role이 있는지 검사한다.
 * @param {Array} roles 사용자의 roles
 * @param {Array||String} arr 검사하고자 하는 role
 * */
export const hasAnyRole = (roles, arr) => {
    const rolesMap = roles.reduce(function (map, obj) {
        map[obj] = true;
        return map;
    }, {});

    if (typeof arr === 'string') {
        return !!rolesMap[arr];
    } else {
        for (let i = 0, ic = arr.length; i < ic; i++) {
            if (rolesMap[arr[i]] !== undefined) {
                return true;
            }
        }
    }

    return false;
}

export const flatten = (into, node) => {
    if (node == null) return into;
    if (Array.isArray(node)) return node.reduce(self.flatten, into);
    into.push(node);
    return self.flatten(into, node.children);
}

export const getShortUuid = () => {
    const _s4 = function () {
        return ((1 + Math.random()) * 0x10000 | 0).toString(16).substring(1);
    };
    return _s4() + _s4() + _s4();
}

export const getFileExtension = (filename) => {
    return filename.split(".").pop()
}

/**
 * jo
 *
 * @param {String||Object} jsonObject JSON
 * @return {Element} <div class="print-json-root"></div>
 */

export function printJSONObject(jsonObject) {
    /*function jsonToString(e) {
        var t = typeof e;
        if ('object' != t || null === e) return 'string' == t && (e = '"' + e + '"'), String(e);
        var n, r, s = [], a = e && e.constructor == Array;
        for (n in e) r = e[n], t = typeof r, 'string' == t ? r = '"' + jsonEscape(r) + '"' : 'object' == t && null !== r && (r = jsonToString(r)), s.push((a ? '' : '"' + jsonEscape(n) + '":') + String(r));
        return (a ? '[' : '{') + String(s) + (a ? ']' : '}');
    }

    function jsonEscape(e) {
        return e.replace(/\\n/g, '\\n').replace(/'/g, '\\\'').replace(/"/g, '\\"').replace(/&/g, '\\&').replace(/\r/g, '\\r').replace(/\t/g, '\\t').replace(/\b/g, '\\b').replace(/\f/g, '\\f');
    }*/

    function stringToJson(str) {
        return JSON.parse(str);
    }

    function isArray(e) {
        try {
            return Array.isArray(e);
        } catch (t) {
            return void 0 !== e.length;
        }
    }

    function objLength(e) {
        var t = 0;
        try {
            t = Object.keys(e).length;
        } catch (n) {
            for (var r in e) Object.prototype.hasOwnProperty.call(e, r) && t++;
        }
        return t;
    }

    if ('string' == typeof jsonObject && (jsonObject = stringToJson(jsonObject)), void 0 === window.printJSONExpandableCallback) {
        try {
            var cssText = '';
            cssText += '.print-json-root ul{ margin: 0; padding: 0; }.print-json-root ul li > ul{ padding-left: 20px; } .print-json-root li{list-style: none;}\n', cssText += '.print-json-string{color:red;}\n', cssText += '.print-json-boolean{color:#7f0055;}\n', cssText += '.print-json-number{color:blue;}\n', cssText += '.print-json-key{color:#881391;}\n', cssText += '.print-json-icon{vertical-align:top;display: inline-block;width: 15px;height: 15px;background-repeat: no-repeat;background-position: 3px 4px;}\n', cssText += '.print-json-plus{cursor:pointer;background-image:url(data:image/gif;base64,R0lGODlhCQAJAIABAAAAAP///yH5BAEAAAEALAAAAAAJAAkAAAIRhI+hG7bwoJINIktzjizeUwAAOw==);}\n', cssText += '.print-json-minus{cursor:pointer;background-image:url(data:image/gif;base64,R0lGODlhCQAJAIABAAAAAP///yH5BAEAAAEALAAAAAAJAAkAAAIQhI+hG8brXgPzTHllfKiDAgA7);}\n', cssText += '.print-json-array{cursor:pointer;background-image:url(data:image/gif;base64,R0lGODlhCQAJAIABAAAAAP///yH5BAEAAAEALAAAAAAJAAkAAAIRhI+hG7bwoJINIktzjizeUwAAOw==);}\n', cssText += '.print-json-table{border-collapse:collapse;}\n';
            var style = document.createElement('style');
            style.setAttribute('type', 'text/css'), style.setAttribute('id', 'printJSONInternalCSS'), document.getElementsByTagName('head')[0].appendChild(style);
            try {
                style.styleSheet.cssText = cssText;
            } catch (e) {
                style.innerHTML = cssText;
            }
        } catch (e) {
            alert('style 생성 실패 : \n' + e.message);
        }
        window.printJSONExpandableCallback = function (e, t) {
            for (var n = t.nextSibling; "UL" !== n.nodeName;) n = n.nextSibling;
            if (n) {
                var r = n.style.display;
                if ('none' === r) {
                    n.style.display = '';
                    t.className = 'print-json-icon print-json-minus';
                    var s = t.jsonData;
                    for (var a in s) {
                        var i = s[a], o = 0, l = typeof i, c = i, p = 'print-json-' + l, d = null;
                        'object' === l && (isArray(i) ? (o = i.length, c = 'Array[' + o + ']', o > 0 && (d = document.createElement('I'), d.setAttribute('class', 'print-json-icon print-json-plus'), d.setAttribute('onclick', 'printJSONViewArrayCallback(event,this)'), d.jsonData = i)) : (o = objLength(i), c = 'Object'));
                        var A = document.createElement('LI'), u = document.createElement('span'),
                            j = document.createElement('SPAN');
                        j.setAttribute('class', 'print-json-key'), j.appendChild(document.createTextNode(a));
                        var b = document.createTextNode(': '), h = document.createElement('SPAN');
                        h.setAttribute('class', p), h.appendChild(document.createTextNode(c)), null != d && h.appendChild(d);
                        var m = document.createElement('I');
                        if (m.setAttribute('class', 'print-json-icon'), o > 0 && (m.setAttribute('class', 'print-json-icon print-json-plus'), m.setAttribute('onclick', 'printJSONExpandableCallback(event,this)'), m.jsonData = i), u.appendChild(j), u.appendChild(b), u.appendChild(h), A.appendChild(m), A.appendChild(u), o > 0) {
                            var g = document.createElement('ul');
                            g.style.display = 'none', A.appendChild(g);
                        }
                        n.appendChild(A);
                    }
                } else n.style.display = 'none', t.className = 'print-json-icon print-json-plus', n.innerHTML = '';
            }
        }, window.printJSONViewArrayCallback = function (e, t) {
            try {
                if ('print-json-icon print-json-plus' === t.className) {
                    var s, a, u, i;
                    t.className = 'print-json-icon print-json-minus';
                    var n = t.jsonData, r = '<thead><tr>';
                    if (r += '<th>seq</th>', n.length > 0) {
                        s = n[0];
                        a = typeof s;
                        if ('object' === a) for (i in s) r += '<th>' + i + '</th>'; else r += '<th>contents</th>';
                    }
                    r += '</tr></thead>';
                    for (var o = [], l = 0, c = n.length; c > l; l++) {
                        var p = '<tr>';
                        p += '<td>' + l + '</td>';
                        s = n[l];
                        a = typeof s;
                        if ('object' === a) for (i in s) p += '<td>' + s[i] + '</td>'; else p += '<td>' + s + '</td>';
                        p += '</tr>\n';
                        o.push(p);
                    }
                    var d = '<tbody>' + o.join('') + '</tbody>', A = document.createElement('table');
                    A.setAttribute('border', '1'), A.setAttribute('class', 'table table-striped table-bordered table-condensed print-json-table'), A.innerHTML = r + d;
                    for (u = t.parentNode; "LI" !== u.nodeName;) u = u.parentNode;
                    try {
                        u.insertBefore(A, u.getElementsByTagName('UL')[0]);
                    } catch (j) {
                        u.appendChild(A);
                    }
                } else {
                    t.className = 'print-json-icon print-json-plus';
                    for (u = t.parentNode; "LI" !== u.nodeName;) u = u.parentNode;
                    u.removeChild(u.getElementsByTagName('TABLE')[0]);
                }
            } catch (j) {
                console.log(j);
            }
        };
    }
    var ul = document.createElement('UL'), li = document.createElement('LI'), span = document.createElement('SPAN'),
        text = document.createTextNode('Object:' + typeof jsonObject), icon = document.createElement('I');
    icon.setAttribute('class', 'print-json-icon'), icon.setAttribute('class', 'print-json-icon print-json-plus'), objLength(jsonObject) > 0 && (icon.setAttribute('onclick', 'printJSONExpandableCallback(event,this)'), icon.jsonData = jsonObject);
    var childUL = document.createElement('UL');
    childUL.style.display = 'none', span.appendChild(text), li.appendChild(icon), li.appendChild(span), li.appendChild(childUL), ul.appendChild(li);
    var div = document.createElement('DIV');
    return div.setAttribute('class', 'print-json-root'), div.setAttribute('style', 'position:relative'), div.appendChild(ul), div;
}

export const getCmMap = function (url) {
    const map = {}
    const urlPrefix = 'https://ad.doubleclick.net/ddm/'
    if (url && url.startsWith(urlPrefix)) {
        const inner = {}

        let newUrl = url.substring(urlPrefix.length)
        let sp = {}
        let functionKey = null;

        if (newUrl.startsWith('socialclk')) {
            functionKey = "socialclk";
            newUrl = newUrl.substring(functionKey.length + 1);  // ? 이후 제거
            sp = newUrl.split("&");
        } else {
            const firstSlashIndex = newUrl.indexOf('/');
            functionKey = newUrl.substring(0, firstSlashIndex);
            newUrl = newUrl.substring(firstSlashIndex + 1);
            sp = newUrl.split(";");
        }

        if ("clk" === functionKey) {
            const spN = newUrl.split("%3f");
            if (spN.length === 2) {
                Object.assign(map, getCmMap(spN[1]));
            }

            const sp0 = spN[0].split(";");
            map['ds_ad_id'] = sp0[0];
            map['ds_placement_id'] = sp0[1];
        } else if ('trackclk' === functionKey) {
            sp.forEach(keyValue => {
                const kv = keyValue.split("=");
                if (kv.length === 2) {
                    inner[kv[0]] = kv[1]
                }
            })

            map['cm_ad_id'] = inner['dc_trk_aid']
            map['cm_creative_id'] = inner['dc_trk_cid']
            const campla = sp[0].substring(sp[0].indexOf("/B") + 2).split(".")
            map['cm_campaign_id'] = campla[0]
            if (campla.length === 2) {
                map['cm_placement_id'] = campla[1]
            }
        }
    }
    return map
}

export const removeArrayItem = (array, target) => {
    if (!array.includes(target)) {
        return;
    }
    array.splice(array.indexOf(target), 1);
}

export const validateUrl = (url = '') => {
    if (!url) {
        return false;
    }

    if (url.includes('??')) {
        return false;
    }

    if (url.includes('&&')) {
        return false;
    }

    if(!url.startsWith('https://ad.doubleclick') && !url.startsWith('https://www.mercuryproject.com') && !url.startsWith('https://bridge.mercuryproject.com')) {
        const qmMatched = url.match(/[?]/g)
        if (isArray(qmMatched) && qmMatched.length > 1) {
            return false
        }
    }

    const expression = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/gm
    return expression.test(url.trim());
}

export const isDuplicateArrayItem = (array) => {
    const isDup = array.some(function (x) {
        return array.indexOf(x) !== array.lastIndexOf(x)
    })

    return isDup
}

export const getKoreanNumber = (number) => {

    number = Number(number.replace(/,/gi, ''))

    const koreanUnits = ['', '만', '억', '조'];
    let answer = '';
    let unit = 10000;
    let index = 0;
    let division = Math.pow(unit, index);

    while (Math.floor(number / division) > 0) {
        const mod = Math.floor(number % (division * unit) / division);
        if (mod) {
            const modToString = mod.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            answer = `${modToString}${koreanUnits[index]} ` + answer;
        }
        division = Math.pow(unit, ++index);
    }
    return answer;
}

export const numberToLetters = (col, row = null) => {
    col -= 1
    let letters = ''
    while (col >= 0) {
        letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[col % 26] + letters
        col = Math.floor(col / 26) - 1
    }
    return row === null ? letters : `${letters}:${row}`
}

export const shortWord = (origin = '') => {
    const split = origin.split(" ")
    if (split.length == 1) {
        return origin
    }

    return split.map(e => e.charAt(0).toUpperCase()).join('')
}

export const removeDummyChars = (text) => {
    return text.replace(/[\u200B-\u200D\uFEFF]/g, '')
}

export const isJsonString = (str) => {
    try {
        var json = JSON.parse(str);
        return (typeof json === 'object');
    } catch (e) {
        return false;
    }
}
