export default {
    namespaced: true,
    state: {
        overlay: false,
        clients: [],
        clientsMap: {},
        user: {},
        activeEmployees: [],
        organizationTree: [],
        chatTagOptions: {},
        platformAdProducts: {},  //TODO platformAdProducts는 추후 삭제 될 예정
        adPlatforms: {},
        adConfigs: {},
        unmanagedPlatforms: [],
        myApproveList: [],
        enableSchedule: false,
        badge: {
            assignedTicketCount: null,
            writingTicketCount: null,
            totalTicketCount: null,
            pendingTicketCount: null,
            pendingContractCount: null,
        },
        appInfo: {
            version: null,
            hash: null
        }
    },
    getters: {
        getOverlay: state => state.overlay,
        getClients: state => state.clients,
        getClientsMap: state => state.clientsMap,
        getUser: state => state.user,
        getActiveEmployees: state => state.activeEmployees,
        getOrganizationTree: state => state.organizationTree,
        getBadge: state => state.badge,
        getChatTagOptions: state => state.chatTagOptions,
        getPlatformAdProducts: state => state.platformAdProducts,
        getAdPlatforms: state => state.adPlatforms,
        getAdConfigs: state => state.adConfigs,
        getUnmanagedPlatforms: state => state.unmanagedPlatforms,
        getMyApproveList: state => state.myApproveList,
        getAppBarBadgeCount: state => {
            return state.myApproveList.length
        },
        getEnableSchedule: state => {
            return state.enableSchedule
        },
        getAppInfo: state => state.appInfo,
    },
    mutations: {
        setAdConfigs: (state, adConfigs) => {
            state.adConfigs = adConfigs;
        },
        setEnableSchedule: (state, enableSchedule) => {
            state.enableSchedule = enableSchedule;
        },
        setUnmanagedPlatforms: (state, unmanagedPlatforms) => {
            state.unmanagedPlatforms = unmanagedPlatforms;
        },
        setAdPlatforms: (state, adPlatforms) => {
            state.adPlatforms = adPlatforms;
        },
        setPlatformAdProducts: (state, platformAdProducts) => {
            state.platformAdProducts = platformAdProducts;
        },
        setChatTagOptions: (state, chatTagOptions) => {
            state.chatTagOptions = chatTagOptions;
        },
        setOrganizationTree: (state, organizationTree) => {
            state.organizationTree = organizationTree;
        },
        setActiveEmployees: (state, activeEmployees) => {
            state.activeEmployees = activeEmployees;
        },
        setMyApproveList: (state, myApproveList) => {
            state.myApproveList = myApproveList
        },
        setOverlay: (state, overlay) => {
            state.overlay = overlay;
        },
        setClients: (state, clients) => {
            state.clients = clients
            const map = {}
            clients.forEach(client => {
                map[client.id] = client
            })
            state.clientsMap = map
        },
        setUser: (state, user) => {
            user['rolesMap'] = user.roles.reduce(function (map, obj) {
                map[obj] = true;
                return map;
            }, {});

            state.user = user;
        },
        setBadge: (state, badge) => {
            Object.assign(state.badge, badge);
        },
        setAppInfo: (state, appInfo) => {
            state.appInfo = appInfo;
        },
    },
    actions: {
        setOverlay({commit}, overlay) {
            commit('setOverlay', overlay);
        },
        setUser({commit}, user) {
            commit('setUser', user);
        },
        setBadge({commit}, badge) {
            commit('setBadge', badge);
        },
        setChatTagOptions({commit}, chatTagOptions) {
            commit('setChatTagOptions', chatTagOptions);
        },
        setPlatformAdProducts({commit}, platformAdProducts) {
            commit('setPlatformAdProducts', platformAdProducts);
        },
        setAdPlatforms({commit}, adPlatforms) {
            commit('setAdPlatforms', adPlatforms);
        },
        setAdConfigs({commit}, adConfigs) {
            commit('setAdConfigs', adConfigs);
        },
        setMyApproveList({commit}, myApproveList) {
            commit('setMyApproveList', myApproveList);
        },
        setAppInfo({commit}, appInfo) {
            commit('setAppInfo', appInfo);
        },
        initStore({commit}, data) {
            if (data !== undefined) {
                if (data['user']) {
                    commit('setUser', data['user']);
                }

                if (data['clients']) {
                    commit('setClients', data['clients']);
                }
                if (data['activeEmployees']) {
                    commit('setActiveEmployees', data['activeEmployees']);
                }
                if (data['organizationTree']) {
                    commit('setOrganizationTree', data['organizationTree']);
                }
                if (data['adPlatforms']) {
                    commit('setAdPlatforms', data['adPlatforms']);
                }
                if (data['adConfigs']) {
                    commit('setAdConfigs', data['adConfigs']);
                }
                if (data['unmanagedPlatforms']) {
                    commit('setUnmanagedPlatforms', data['unmanagedPlatforms']);
                }

                if (data['enableSchedule'] != null) {
                    commit('setEnableSchedule', data['enableSchedule']);
                }

            } else {
                this._vm.xAjax({
                    url: '/base/users/me'
                }).then(resp => {
                    commit('setUser', resp);
                })
            }

            //this.$store.dispatch('base/getUser', '112121'); 로 호출가능
            //store 내에서는 this._vm으로 vm 접근 가능
        }
    }
};
