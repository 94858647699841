import {subscribe, unsubscribe} from '@/websocket';
import {
    CAPSULE_TICKET_STATUS,
    CAPSULE_TICKET_USER,
    CAPSULE_GLOBAL_ACTIVE_USERS,
    CAPSULE_GLOBAL_MESSAGE,
    CAPSULE_USER_MESSAGE, CAPSULE_TICKET_EVENT
} from '@/websocket/subscribe-types';

export function subscribeTicketEvent(tickerOrderId, callback) {
    subscribe(CAPSULE_TICKET_EVENT, `/topic/ticket.event.${tickerOrderId}`, payload => {
        callback(payload.eventBody);
    });
}
export function subscribeTicketStatus(ticketId, callback) {
    subscribe(CAPSULE_TICKET_STATUS, `/topic/ticket.status.${ticketId}`, payload => {
        callback(payload.eventBody);
    });
}

export function subscribeTicketUser(userId, callback) {
    subscribe(CAPSULE_TICKET_USER, `/topic/ticket.user.${userId}`, payload => {
        callback(payload.eventBody);
    });
}


export function subscribeGlobalActiveUsers(callback) {
    subscribe(CAPSULE_GLOBAL_ACTIVE_USERS, `/topic/global.activeUsers`, payload => {
        callback(payload.eventBody);
    });
}

export function subscribeGlobalMessage(callback) {
    subscribe(CAPSULE_GLOBAL_MESSAGE, `/topic/global.message`, payload => {
        callback(payload.eventBody);
    });
}

export function subscribeUserMessage(userId, callback) {
    subscribe(CAPSULE_USER_MESSAGE, `/topic/user.message.${userId}`, payload => {
        callback(payload.eventBody);
    });
}

export function unsubscribeTicketStatus() {
    unsubscribe(CAPSULE_TICKET_STATUS);
}

export function unsubscribeTicketUser() {
    unsubscribe(CAPSULE_TICKET_USER);
}

export function unsubscribeGlobalActiveUsers() {
    unsubscribe(CAPSULE_GLOBAL_ACTIVE_USERS);
}

export function unsubscribeGlobalMessage() {
    unsubscribe(CAPSULE_GLOBAL_MESSAGE);
}

export function unsubscribeUserMessage() {
    unsubscribe(CAPSULE_USER_MESSAGE);
}

export function unsubscribeTicketEvent() {
    unsubscribe(CAPSULE_TICKET_EVENT);
}





