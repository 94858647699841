import toastr from "toastr";
import $ from 'jquery';
import Swal from 'sweetalert2';
import {cloneDeep, isArray, trim} from 'lodash'
import i18n from "@locales/i18n";
import {shortUuid} from "@plugins/MercuryBaseUtils";


export const ERROR_PAGE = '/error.html'
export const API_HOST = process.env.VUE_APP_API_HOST + '/api/v2';
export const AD_HUB_API_HOST = process.env.VUE_APP_AD_HUB_API_HOST + '/api/v2';
export const CCA_HOST = process.env.VUE_APP_CCA_API_HOST;
export const messages = {
  PROCESS: '요청하신 작업이 처리되었습니다.'
}

export const API_HOSTS = {
  'capsule': API_HOST,
  'ad_hub': AD_HUB_API_HOST,
  'cca': CCA_HOST
}

const _headers = {};

const _tokenTypeKey = 'authorizationType';
const _accessTokenKey = 'accessToken';
const _refreshTokenKey = 'refreshToken';

export function setHeader(key, value) {
  _headers[key] = value;
}

export function getHeader(key) {
  return _headers[key]
}

export const getAuthorization = () => {
  const authorizationType = window.localStorage.getItem(_tokenTypeKey);
  const accessToken = window.localStorage.getItem(_accessTokenKey);

  if (authorizationType && accessToken) {
    return `${authorizationType} ${accessToken}`;
  }

  return null;
}

export const setAuthorization = (accessToken, refreshToken, authType = 'bearer') => {
  window.localStorage.setItem(_tokenTypeKey, authType);
  window.localStorage.setItem(_accessTokenKey, accessToken);
  window.localStorage.setItem(_refreshTokenKey, refreshToken);
}

export const clearAuthorization = () => {
  window.localStorage.removeItem(_tokenTypeKey);
  window.localStorage.removeItem(_accessTokenKey);
  window.localStorage.removeItem(_refreshTokenKey);
}

export const excludeMenuByGroup = (menuList, userGroups) =>{
  const findFunc = (menu) => {
    const menuAuth = menu.roles;
    if (menuAuth === null || menuAuth === undefined) {
      return false;
    }

    for (let i = 0, ic = menuAuth.length; i < ic; i++) {
      if (userGroups.includes(menuAuth[i])) {
        return false;
      }
    }

    return true;
  };

  let idx = menuList.findIndex(findFunc);
  while (idx > -1) {
    menuList.splice(idx, 1)
    idx = menuList.findIndex(findFunc);
  }

  for( let j = 0, jc = menuList.length ; j < jc; j++){
    excludeMenuByGroup (menuList[j].children, userGroups)
  }
}

export const menuToRoutes = (menu, routes, userRoles) => {
  if (menu.children && menu.children.length > 0) {
    menu.children.forEach(childMenu => {
      menuToRoutes(childMenu, routes, userRoles);
    });
  } else {
    if (menu.path) {
      let canAccess = true
      if(menu.roles){
        canAccess = false
        if(userRoles){
          menu.roles.forEach(mr=>{
            if(userRoles.includes(mr)){
              canAccess = true
            }
          })
        }
      }

      const name = menu.path.substring(menu.path.lastIndexOf('/') + 1);

      let componentPath = menu.path;
      if (componentPath.includes("/:")) {
        componentPath = componentPath.substring(0, componentPath.indexOf('/:'))
      }


      routes.push({
        name: name,
        label: menu.name,
        path: menu.path,
        component: () => canAccess ? import(`../views${componentPath}`) : import(`@common/components/ui/MpUnauthorized`),
        meta: {
          title: menu.name,
          menu: menu
        }
      });
    }
  }
};

$.ajaxPrefilter(function (opts, originalOpts, jqXHR) {
  if (!opts.ignoreAuthorization && getAuthorization()) {
    jqXHR.setRequestHeader('Authorization', getAuthorization());
  }

  if (opts.refreshRequest) {
    return;
  }

  const deferred = $.Deferred();

  jqXHR.done(deferred.resolve);

  jqXHR.fail(function () {
    const args = Array.prototype.slice.call(arguments);

    if (jqXHR.status === 401 || (jqXHR.responseJSON && jqXHR.responseJSON.message.includes('expired token'))) {
      const refreshToken = window.localStorage.getItem(_refreshTokenKey);
      const accessToken = window.localStorage.getItem(_accessTokenKey);

      if(!refreshToken || !accessToken) {
        localStorage.setItem("__redirect__url", location.href)
        return location.href = '/login.html';
      }

      xAjax({
        url: '/auth/refresh',
        method: 'POST',
        data: {
          refreshToken: refreshToken
        },
        ignoreAuthorization: true,
        refreshRequest: true
      }).then(response => {

        if (response.errorCode) {
          const message = response.message;

          if (message.toLowerCase().includes('expired')) {
            return alert('인증정보가 만료되었습니다.\n로그인페이지로 이동합니다.', {title: '인증정보 만료'}).then(() => {
              localStorage.setItem("__redirect__url", location.href)
              location.href = '/login.html';
            });
          }

          alert(response.message, {title: '인증오류'}).then(() => {
            localStorage.setItem("__redirect__url", location.href)
            location.href = '/login.html';
          });

          return;
        }

        if (response.accessToken && response.refreshToken) {
          setAuthorization(response.accessToken, response.refreshToken, 'bearer')
        }

        const newOpts = $.extend({}, originalOpts, {refreshRequest: true});
        $.ajax(newOpts);

      }).catch(errorXHR => {
        console.log(errorXHR)

        if(errorXHR.status === 401) {
          return alert('인증정보가 만료되었습니다.\n로그인페이지로 이동합니다.', {title: '인증정보 만료'}).then(() => {
            localStorage.setItem("__redirect__url", location.href)
            location.href = '/login.html';
          });
        }

        const error = errorXHR['responseJSON'];
        if (error === undefined) {
          notify('[' + errorXHR.status + ']사용자 요청이 실패하였습니다.', {title: '오류'}, {type: 'danger'});
        } else {
          notify(error.message, {title: error['error']}, {type: 'danger'});
        }
      })
    } else {

      if(jqXHR.status === 0){//status가 0 인경우는 API 서버가 죽어있는경우?
        location.href=ERROR_PAGE
        return
      }

      deferred.rejectWith(jqXHR, args);
    }
  });

  return deferred.promise(jqXHR);
});


export const xAjax = (options) => {
  if (options['usePrefixUrl'] !== false) {
    if(options['apiHost'] === undefined){
      options['url'] = API_HOST + options['url'];
    }else{
      options['url'] = API_HOSTS[options['apiHost']] + options['url'];
    }
  }

  if (options['headers'] !== undefined && options['headers'] !== null) {
    for (const [key, value] of Object.entries(options['headers'])) {
      setHeader(key, value);
    }
  }

  const paramError = options.error;
  return new Promise(function (resolve, reject) {
    const dOptions = {
      xhrFields: {
        withCredentials: false
      },
      beforeSend: function(xhr) {
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      },
      success: function (response, textStatus, jqXHR) {
        options.__status = textStatus
        options.__xhr = jqXHR
        resolve(response);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        if (typeof paramError === 'function') {
          paramError(jqXHR, textStatus, errorThrown);
        }

        const status = jqXHR.status;
        const stautsText = jqXHR.statusText
        const error = jqXHR['responseJSON'];

        if (error === undefined) {  //JSON Error가 없다는건 HTML로 에러가 떨어졌다.
          const respText = jqXHR['responseText'];
          if (respText !== undefined && respText.startsWith('<!DOCTYPE')) {//python error page
            const regex = /(<pre class="exception_value">)(.*?)(<\/pre>)/ig
            const errorText = regex.exec(respText);
            if (errorText !== undefined) {
              try {
                notify({message: errorText[2], title: stautsText, type: 'error'});
              } catch (e) {
                notify({message: "[" + jqXHR.status + "]사용자 요청이 실패하였습니다.", title: stautsText, type: 'error'});
              }

              reject(error);
              return;
            }
          }

          if (status === 401) {
            notify({message: '세션이 만료되었습니다.', title: '세션만료' + stautsText, type: 'error'});
            reject(error);
          } else {
            notify({message: "[" + jqXHR.status + "]사용자 요청이 실패하였습니다.", title: "통신오류", type: 'error'});
            reject(error);
          }
        } else {
          /*if (status === 400) {

            const errors = error.errors;
            if(errors !== undefined){
              let message = '<ul>';
              for (let i = 0, ic = errors.length; i < ic; i++) {
                const errorDesc = errors[i];
                message += '<li>[' + errorDesc['field'] + ':' + errorDesc['value'] + ']<br/> ' + errorDesc['reason'] + '<br/><br/></li>';
              }
              message += '</ul>';

              notify({message: message, title: '[' + status + ']' + error.error, type: 'error'});
            }

            reject(error);
            return;
          }*/

          notify({message: error.message, title: '[' + status + ']' + error.error, type: 'error'});
          if(options.vm) {
            options.vm.loading = false;
          }
          reject(error);
        }
      }
    };

    delete options.error;
    $.ajax($.extend({}, dOptions, options))
  });
}

/**
 * xAjax와 동일하나 contentType을 application/json으로 고정하고
 * Object 형태의 데이터를 stringify 하여 body데이터로 보낸다.
 *
 * 보통 POST,PATCH,PUT 등의 전송에서 Controller @RequestBody 를 통해 데이터 바인딩을 수행할 때 사용된다.
 * */
export const xAjaxJson = (options) => {
  const jsonOption = {
    contentType: "application/json"
  };

  if (typeof options.data === 'object') {
    jsonOption['data'] = JSON.stringify(options.data);
  } else {//이미 stringify 된경우
    jsonOption['data'] = options.data;
  }

  return xAjax(Object.assign(options, jsonOption));
}

export const xAjaxMultipart = (options) => {
  const formData = new FormData();

  if (options.parts) {
    for (const [key, value] of Object.entries(options.parts)) {
      const blob = new Blob([JSON.stringify(value)], {
        type: 'application/json'
      });
      formData.append(key, blob);
    }
  }

  if (options.fileParts) {
    for (const [key, value] of Object.entries(options.fileParts)) {
      if (Array.isArray(value)) {
        value.forEach(e => {
          formData.append(key, e);
        })

      } else {
        formData.append(key, value);
      }
    }
  }

  return xAjax(Object.assign(options, {
    contentType: false,
    processData: false,
    data: formData
  }));
}

export const removeArrayItem = (array, index) => {
  const _indexes = isArray(index) ? index : [index];
  for (let i = _indexes.length - 1; i >= 0; i--) {
    index.splice(_indexes[i], 1);
  }

}

//xCode 과거 호환용
export const code =  (code, options) => {
  return xCode(code, options)
}

export const xACode = (div, code) => {
  return mercury.base.company.code.CODES[div].find(e => e.code === code) || {code: code, text: code, name: code};
}

export const xCode = (code, options = {}) => {
  const locale = (options && options.locale) ? options.locale : i18n.locale;

  const dOptions = {
    type: 'v-object',   //option,
    useYn: 'Y',
    defaultValue: '',
    rename: null
  };
  options = Object.assign(dOptions, options || {});

  let children = mercury.base.company.code.CODES[code] || [];
  children = cloneDeep(children)

  if (options.useYn !== 'ALL') {
    children = children.filter(child => child.useYn === options.useYn);
  }

  if (options.rename) {
    children.map(child => {
      const rename = options.rename[child.name]
      if (rename) {
        child.originName = child.name
        if (rename['ko']) {
          child.nameKo = rename['ko']
        }
        if (rename['en']) {
          child.nameEn = rename['en']
        }
        if (!rename['ko'] && !rename['en']) {
          child.name = rename
        }
      }
    })
  }

  if (locale) {
    const changeCodeName = function(codes, locale) {
      codes.forEach(code => {
        code.name = (locale === 'en' ? code.nameEn : code.nameKo) || code.name;

        if (code.children) {
          changeCodeName(code.children, locale)
        }
      });
    }

    changeCodeName(children, locale)
  }

  if (options.type === 'option') {
    let option = '';

    children.forEach(child => {
      let selected = '';
      if (options['defaultValue'] === child['code']) {
        selected = ' selected="selected" '
      }

      option += `<option value="${child.code}" ${selected} data-div-cd="${child.divCd}" data-etc1="${child.etc1}" data-etc2="${child.etc2}" data-etc3="${child.etc3}" data-etc4="${child.etc4}">${child.name}</option>`;
    });
    return option;
  } else if (options.type === 'v-object') {
    children.forEach(child => {
      child.text = child.name;
      child.value = child.code;
    });
  }

  return children;
}

export const multipleDownload = (fileKeys, filename, readme) => {

  if (!isArray(fileKeys) || fileKeys.length == 0) {
    return notify({message: '파일키 오류', type: 'error'});
  }

  if (!filename) {
    filename = shortUuid();
  }

  const $form = $('<form method="POST"/>');
  $form.attr('action', API_HOST + "/file/multipleDownload");
  $form.append(`<input type="hidden" name="filename" value="${filename}"/>`);
  fileKeys.forEach(key => {
    $form.append(`<input type="hidden" name="key" value="${key}"/>`);
  });

  if(readme) {
    $form.append(`<input type="hidden" name="readme" value="${readme}"/>`);
  }

  $form.appendTo('body');
  $form.submit();
  $form.remove();
}


export const download = (options, paramFileName = null, json = false) => {
  const opt = Object.assign({},{
    cache: false,
    xhrFields: {
      responseType: "blob",
    }
  }, options);


  const promise = json === true ? xAjaxJson(opt) : xAjax(opt)

  return promise.then(function (blob) {

    // check for a filename
    let fileName = paramFileName? paramFileName : "untitled";

    if(fileName === 'untitled' && opt.__xhr && opt.__xhr.getResponseHeader) {
      const disposition = opt.__xhr.getResponseHeader("Content-Disposition");

      if (disposition && disposition.indexOf("attachment") !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);

        if (matches != null && matches[1]) {
          fileName = decodeURI(matches[1].replace(/['"]/g, ""));
        }
      }
    }

    // for IE
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const URL = window.URL || window.webkitURL;
      const downloadUrl = URL.createObjectURL(blob);

      if (fileName) {
        const a = document.createElement("a");
        // for safari
        if (a.download === undefined) {
          window.location.href = downloadUrl;
        } else {
          a.href = downloadUrl;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
        }
      } else {
        window.location.href = downloadUrl;
      }
    }

    return Promise.resolve(fileName)
  }).catch(error=>{
    return new Promise(function (resolve, reject) {
      reject(error);
    });
  })
}

export const download2 = (options) => {


  return new Promise(function (resolve, reject) {
    // https://github.com/johnculviner/jquery.fileDownload/issues/129
    // successCallback 이 호출되지 않는 경우 response 에 set cookie fileDownload=true
    const defaultOptions = {
      successCallback: function (url) {
        resolve({url: url});
      },
      failCallback: function (responseHtml, url, error) {
        let json;
        try {
          let jsonString = responseHtml.replace(/<[^>]*>?/gm, '');
          jsonString = jsonString.replace(/\n/g, '')
          json = JSON.parse(jsonString);
          notify({message: json.detail, type: 'error'});
        } catch (e) {
          json = undefined;
          notify({message: 'The file could not be found.', type: 'error'});
        }

        reject({html: responseHtml, url: url, json: json});
      }
    };
    defaultOptions.data = options.data;
    defaultOptions.httpMethod = options.method || 'GET';

    $.fileDownload(options.url, defaultOptions);
  });
}

export const prompt = (title, options) => {
  options = options || {};
  title = title.replace(/\n/g, '<br/>');
  const dOptions = {
    html: title,
    input: 'text',
    allowOutsideClick: false,
    inputAttributes: {
      autocapitalize: 'off'
    },
    showCancelButton: true,
    confirmButtonText: 'OK',
    cancelButtonText: 'Cancel',
    inputValidator: (value) => {
      if (!trim(value)) {
        return options['validation'] || 'Required field.'
      }
    }
  };

  return Swal.fire($.extend({}, dOptions, options));
}

export const alert = (msg, options) => {
  msg = msg.replace(/\n/g, '<br/>');
  options = options || {};
  const dOptions = {
    html: msg,
    //title: "Are you sure?",
    //icon: "warning",
    showCancelButton: false,
    confirmButtonText: "OK"
  };

  return Swal.fire($.extend({}, dOptions, options));
}

export const confirm = (msg, options) => {
  msg = msg.replace(/\n/g, '<br/>');
  options = options || {};
  const dOptions = {
    html: msg,
    heightAuto: false,
    showCancelButton: true,
    confirmButtonText: "OK",
    cancelButtonText: "Cancel",
    reverseButtons: true
  };

  return Swal.fire($.extend({}, dOptions, options));
}

export const notify = (msg, options, settings) => {
  //https://github.com/CodeSeven/toastr
  //https://codeseven.github.io/toastr/demo.html

  let toastType = 'success'; //warning, success, error, info
  let toastTitle = undefined;
  let toastMessage;
  let positionClass = "toast-bottom-left";
  let timeOut = "2000";

  if (typeof msg === 'object') {
    if (msg['message'] !== undefined) { //type, title, message
      toastMessage = msg['message'];
    } else {                              //POST, PUT 등의 객체 리턴
      toastMessage = messages.PROCESS
    }

    if (msg['type'] !== undefined) {
      toastType = msg['type'];
    }

    if (msg['title'] !== undefined) {
      toastTitle = msg['title'];
    }
  } else if (msg === undefined) {    //DELETE 요청 또는 실제로 없는경우
    toastMessage = messages.PROCESS
  } else {  // 추후 삭제예정
    toastMessage = msg;

    if (settings && settings.type) {
      toastType = settings.type;
    }

    if (options) {
      if(options.title) {
        toastTitle = options.title;
      }

      if(options.timeOut) {
        timeOut = options.timeOut;
      }

    }
  }

  console.log(toastType, toastTitle, toastMessage)


  if (toastType === 'error' && timeOut === "2000") {
    timeOut = "10000";
  }

  toastMessage = toastMessage.replace(/\n/gi, '<br/>')

  return toastr[toastType](toastMessage, toastTitle, {positionClass: positionClass, timeOut: timeOut});
}

export function parseQuery(queryString) {
  const query = {};
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

export function loadScriptSync(src, async, onload) {
  const s = document.createElement('script');
  s.src = src;
  s.type = "text/javascript";
  s.async = async !== false;
  s.onload = onload;

  document.head.appendChild(s);
}

export const tinymce = {
  baseSkinPath: '/static/lib/vue/vue-easy-tinymce-1.0.2/css',
  skinInfo: {
    'black': {
      skin: 'dark',
      path: '/dark/content.css'
    },
    'lightgray': {
      skin: 'lightgray',
      path: '/tinymce-content.css'
    }
  },
  getSkinPath: function (isDark = false) {
    const skin = isDark ? 'black' : 'lightgray';
    return this.baseSkinPath + this.skinInfo[skin].path;
  },
  changeTheme: function (isDark = false) {

    const $tinymceSkinLink = $('link[href*="/tinymce/skins/"]');
    const tinymceSkinLinkHref = $tinymceSkinLink.attr('href');
    const $editorIframe = $('iframe[id*="editor-"]');

    if (isDark) {
      if ($tinymceSkinLink.length > 0) {
        $tinymceSkinLink.attr('href', tinymceSkinLinkHref.replace('lightgray', 'dark'));
      }

      if ($editorIframe.length > 0) {
        const _this = this;
        $editorIframe.contents().find('link').each(function () {
          const $this = $(this);
          const href = $this.attr('href');
          if (href.indexOf('lightgray/content.min.css') > -1) {
            $this.attr('href', href.replace('lightgray/content.min.css', 'dark/content.min.css'));
          } else if (href.indexOf(_this.skinInfo.lightgray.path) > -1) {
            $this.attr('href', href.replace(_this.skinInfo.lightgray.path, _this.skinInfo.black.path));

          }
        });
      }


    } else {
      if ($tinymceSkinLink.length > 0) {
        $tinymceSkinLink.attr('href', tinymceSkinLinkHref.replace('dark', 'lightgray'));
      }
      if ($editorIframe.length > 0) {
        const _this = this;
        $editorIframe.contents().find('link').each(function () {
          const $this = $(this);
          const href = $this.attr('href');
          if (href.indexOf('dark/content.min.css') > -1) {
            $this.attr('href', href.replace('dark/content.min.css', 'lightgray/content.min.css'));
          } else if (href.indexOf(_this.skinInfo.black.path) > -1) {
            $this.attr('href', href.replace(_this.skinInfo.black.path, _this.skinInfo.lightgray.path));
          }
        });
      }
    }
  }
};
