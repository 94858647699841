<template>
  <v-dialog v-model="show" width="700">
    <v-card>
      <v-card-title>
        {{month}}월 P&L 마감 안내
        <v-spacer></v-spacer>
        <v-btn icon @click="show=false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="font-size-18 pa-3">
          <p>
            <template v-if="remainingDays > 0">
              <span class="danger--text">{{ remainingDays }}</span>일 뒤에
            </template>
            <template v-else>
              <span class="danger--text">금일</span>
            </template>
            {{month}}월 P&L이 마감됩니다.</p>
          <p><span class="danger--text">{{ closeDate }}</span> 이후에는 서브티켓 수정이 불가하오니 해당일 전에 마감해 주시기 바랍니다.</p>
          <p>문의사항이 있으시면 경영지원 본부에 문의 바랍니다.</p>
          <p>감사합니다.</p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-checkbox hide-details dense class="mt-1" label="오늘 하루 동안 열지 않기"
                    @change="checkTodayHide($event)"></v-checkbox>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="show=false" outlined raised>닫기</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {subscribe, unsubscribe} from "@/websocket";
import {CAPSULE_PNL_CLOSE} from "@/websocket/subscribe-types";
import {getCookie, setCookie} from "@plugins/MercuryBaseUtils";
import {xAjax} from "@plugins/MercuryBaseLib"
import moment from "moment";

export default {
  name: 'mp-pnl-close-notice',
  props: {},
  data: function () {
    return {
      show: false,
      closeDate: null,
      remainingDays: null,
      month: null,
    }
  },
  computed: {},
  methods: {
    open(closeDate, remainingDays, month) {
      this.closeDate = closeDate
      this.remainingDays = remainingDays
      this.month = month -1
      this.show = true
    },
    isOpenable() {
      return getCookie("CAPSULE_PNL_CLOSE_HIDE") !== 'true'
    },
    checkTodayHide(value) {
      if(value === true) {
        const expires = moment().set('hours', 23).set('minutes', 59).set('seconds', 59);
        setCookie('CAPSULE_PNL_CLOSE_HIDE', 'true', expires.toDate())
        this.show = false
      }
    }
  },
  async mounted() {
    subscribe(CAPSULE_PNL_CLOSE, `/topic/pnl.close.notice`, payload => {
      if (this.isOpenable()) {
        this.open(payload.eventBody.closeDate, payload.eventBody.remainingDays, payload.eventBody.month)
      }
    });


    if (this.isOpenable()) {
      const response = await xAjax({
        url: '/pnlCloseNotice',
        method: 'get'
      })

      if (response.remainingDays != null && response.closeDate) {
        this.open(response.closeDate, response.remainingDays, response.month)
      }
    }


  },
  destroyed() {
    unsubscribe(CAPSULE_PNL_CLOSE)
  }
}
</script>
