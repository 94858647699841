<template>
  <div class="ubcs-navigation-drawer">
    <v-navigation-drawer app v-model="drawer" :width="width">
      <template #prepend>
        <v-toolbar flat dense>
          <a href="/" class="d-flex align-center app-logo">
            <v-img v-if="logo.src" :src="logo.src" :alt="logo.title" class="shrink" :width="logo.width"
                   :height="logo.height"/>
            <span v-if="logo.title" class="title" style="color:#ff7f00 !important;">{{ logo.title }}</span>
          </a>
        </v-toolbar>
      </template>

      <v-list expand v-if="serviceMenu.children && serviceMenu.children.length > 0">
        <mp-menu-group v-if="canAccess(menu.roles)" v-for="menu in serviceMenu.children" :menu="menu" :key="menu.id"/>
      </v-list>

      <template v-slot:append>
        <div>
          <mp-active-user/>
        </div>
      </template>

    </v-navigation-drawer>
  </div>
</template>
<script>
import MpMenuGroup from "./MpMenuGroup";
import {createNamespacedHelpers} from "vuex";
import MpActiveUser from "@common/components/ui/MpActiveUser";

const baseHelper = createNamespacedHelpers('base');

export default {
  name: 'mp-navigation-drawer',
  components: {
    MpActiveUser,
    MpMenuGroup
  },
  model: {
    prop: 'show',
    event: 'change'
  },
  props: {
    logo: {
      type: Object,
      default: () => ({})
    },
    serviceMenu: {
      type: Object,
      default: () => ({})
    },
    show: {
      type: Boolean,
      default: null
    },
    width: {
      type: [Number, String],
      default: 190,
    }
  },
  computed: {
    drawer: {
      get() {
        return this.show;
      },
      set(drawer) {
        this.$emit('change', drawer);
      }
    },
    ...baseHelper.mapGetters([
      'getUser'
    ])
  },
  methods: {
    canAccess(menuRoles) {
      let canAccess = true;
      if (menuRoles) {
        canAccess = false;
        menuRoles.forEach(mr => {
          if (this.getUser.roles.includes(mr)) {
            canAccess = true
          }
        })
      }
      return canAccess;
    }
  }
}
</script>
