import moment from 'moment'
import _, {assignIn, find, get, isArray, isFunction, isNumber, isString} from 'lodash'
import $ from 'jquery'

import * as baseLib from './MercuryBaseLib'
import baseRule from './MercuryBaseRule'
import * as baseSkylab from './MercuryBaseSkylab'
import * as baseUtils from './MercuryBaseUtils'
import i18n from '@locales/i18n'
import MobileDetect from "mobile-detect";
window.API_HOST = baseLib.API_HOST;
window.jQuery = $;
window.$ = $;
window.mercury = {
    base: {
        lib: baseLib,
        rule: baseRule,
        skylab: baseSkylab,
        util: baseUtils
    }
}


const MercuryGlobal = {
    install(Vue, options) {

        Vue.prototype.mercury = window.mercury

        //권한 관련 글로벌 mixIn
        Vue.mixin({
            computed: {
                isMercury() {
                    return this.hasAnyRole('SYSTEM_MANAGER')
                },
                currentUser() {
                    return this.$store.getters['base/getUser']
                }
            },
            methods: {
                setOverlay: function (overlay) {
                    this.$store.dispatch('base/setOverlay', overlay);
                },
                hasAnyRoleM: function (roles) {
                    if (this.isMercury) {
                        return this.hasAnyRole(roles)
                    }
                    return false;
                },
                hasAnyRole: function (roles) {
                    if (typeof roles === 'string') {
                        roles = [roles]
                    }

                    const rolesMap = this.$store.getters['base/getUser'].rolesMap;
                    for (let i = 0, ic = roles.length; i < ic; i++) {
                        if (rolesMap[roles[i]]) {
                            return true
                        }
                    }

                    return false;
                },
                getJsonPath(obj, path) {
                    if(!path.startsWith('$.')){
                        throw Error('JSONPath 표현식은 $. 으로 시작해야 합니다.')
                    }

                    return get(obj, path.substring(2))
                }
            },
        })


        Vue.prototype.moment = moment;
        Vue.prototype._ = _;
        Vue.prototype.xAjax = baseLib.xAjax;
        Vue.prototype.xAjaxJson = baseLib.xAjaxJson;
        Vue.prototype.xAjaxMultipart = baseLib.xAjaxMultipart;

        Vue.prototype.$settings = {
            requestMapping: baseLib.API_HOST,
            datatable: {
                rows2: "90px",
                rows5: "202px",
                rows6: "234px",
                rows10: "362px",
                rows15: "522px",
                rows20: "682px",
                rows25: "844px",
                rows30: "1006px",
                fullHeight: (vh = 100, topPadding = 0) => {
                    return (Math.round(window.innerHeight / (100 / vh)) - topPadding) + 'px'
                },
                footer2: {
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus',
                    itemsPerPageOptions: [2, 10, 50]
                },
                footer5: {
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus',
                    itemsPerPageOptions: [5, 25, 100]
                },
                footer6: {
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus',
                    itemsPerPageOptions: [6, 25, 100]
                },
                footer10: {
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus',
                    itemsPerPageOptions: [10, 20, 50, 100]
                },
                footer15: {
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus',
                    itemsPerPageOptions: [15, 30, 100]
                },
                footer20: {
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus',
                    itemsPerPageOptions: [20, 40, 100]
                },
                footer25: {
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus',
                    itemsPerPageOptions: [25, 50, 100]
                },
                footer30: {
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus',
                    itemsPerPageOptions: [30, 60, 100, 500]
                },
                getItemsPerPageOptions: ((min = 5, max = 100, step = 5,) => (Array(Math.floor((max - min) / step) + 1).fill(min).map(((x, i) => (x + i * step)),)))
            }
        }

        Vue.filter("masking", (value, lastDigit = 6, forceOrigin=false) => {

            if(forceOrigin === true) {
                return value
            }

            const hiddenPart = '*'.repeat(6);
            const modifiedString = value.slice(0, -6) + hiddenPart;
            return modifiedString
        })

        Vue.filter("comma", (val, defaultVal = '') => {

            if(val == 0) {
                return 0
            }

            if (!val) {
                return defaultVal
            }
            let str = String(val).split(".");
            str[0] = str[0].replace(/,/g, '')
            str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return str.join(".");

            // return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        })
        Vue.filter("percent", (val, justSuffix = false, digit = 1) => {

            if (!isNumber(val)) {
                return val
            }

            if(justSuffix === true) {
                return `${val.toFixed(digit)}%`
            }

            let p = val * 100;
            return `${p.toFixed(digit)}%`;

            // return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        })
        Vue.filter("commission", val => {
            if (!isNumber(val)) {
                return val
            }

            return `${Number(val).toFixed(2)}%`;

            // return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        })
        Vue.filter("codeName", (val, codes) => {
            if (isArray(codes)) {
                const code = find(codes, item => item.code === val)

                if (code) {
                    const local = i18n.locale
                    return (local === 'en' ? code['nameEn'] : code['nameKo']) || code['name']
                }
            }
            return val;
        })

        /*Vue.filter('comma', {
          // model -> view  formats the value when updating the input element.
            read: function(val) {
                return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            // view -> model    // formats the value when writing to the data.

            write: function(val, oldVal) {
                return String(val).replace(/,/g, "");
            }
        });*/

        Vue.component('mp-button', {
            template: `
              <v-tooltip top v-if="mode === 'search'">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="d-inline-block">
                  <v-btn :color="color" @click="clickEvent" :small="size === 'small'"
                         class="ml-1 mp-button mp-button-search" :class="className" :aria-label="label"
                         :disabled="disabled" :loading="loading">
                    <v-icon>{{ icon }}</v-icon>
                  </v-btn>
                </div>
              </template>
              <span><slot>{{ label }}</slot></span>
              </v-tooltip>
              <v-tooltip top v-else-if="mode === 'label'">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="d-inline-block">
                  <v-btn :color="color" @click="clickEvent"
                         class="ml-1 mp-button mp-button-label" :class="className" outlined small text :aria-label="label"
                         :disabled="disabled" :loading="loading">
                    <v-icon>{{ icon }} mdi-18px</v-icon>
                    {{ label }}
                  </v-btn>
                </div>
              </template>
              <span style="white-space: pre-line;"><slot>{{ label }}</slot></span>
              </v-tooltip>
              <v-tooltip top v-else-if="mode === 'icon'">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="d-inline-block">
                  <v-icon :color="color" @click="clickEvent"
                          class="ml-1 mp-button mp-button-icon" small :disabled="disabled" :class="className"
                          :aria-label="label">{{ !loading ? icon : 'mdi-cached mdi-spin' }} mdi-{{iconSize}}px
                  </v-icon>
                </div>
              </template>
              <span style="white-space: pre-line;"><slot>{{ label }}</slot></span>
              </v-tooltip>
              <v-btn v-else-if="mode === 'text'" :small="size === 'small'" :color="color" @click="clickEvent"
                     class="mp-button mp-button-text"
                     outlined raised text :class="className" :disabled="disabled" :aria-label="label">
              <v-icon>{{ !loading ? icon : 'mdi-cached mdi-spin' }} mdi-{{iconSize}}px</v-icon>
              <slot>{{ label }}</slot>
              </v-btn>
            `,
            props: {
                mode: {
                    type: String,
                    default: 'search'
                },
                disabled: {
                    type: Boolean,
                    default: false,
                },
                loading: {
                    type: Boolean,
                    default: false,
                },
                className: String,
                label: String,
                color: String,
                click: Function,
                icon: String,
                size: String,
                iconSize: {
                    type: [Number, String],
                    default: '18'
                }
            },
            methods: {
                clickEvent() {
                    this.$emit("click");
                }
            }
        });

        Vue.component('mp-search', {
            template: `
              <v-form class="search-form">
              <v-container :class="classes">
                <v-row class="ubcs-search-div">
                  <slot></slot>
                </v-row>
                <slot name="extends"></slot>
              </v-container>
              </v-form>
            `,
            props: {
                classes: {
                    type: Array,
                    default: () => []
                }
            }
        });

        Vue.component('mp-date-range-picker', {
            template: `
              <div :class="classes">
              <v-md-date-range-picker
                  :label="label"
                  :max-year="maxYear"
                  :min-year="minYear"
                  show-year-select
                  v-model="dModel"
                  :presets="presets"
                  :editable="editable"
                  :multiple="multiple"
                  :type="type"
                  :language="($i18n && $i18n.locale)? $i18n.locale : 'en'"
                  show-total-range-label
                  :custom-locale="locale"
              >
                <template v-slot:picker-input="{label, value}">
                  <slot name="input" v-bind:label="label" v-bind:value="value">
                    <template v-if="shape === 'underline'">
                      <v-text-field :label="label" v-model="value"></v-text-field>
                    </template>
                    <template v-else>
                      <v-text-field :label="label" v-model="value" outlined dense hide-details></v-text-field>
                    </template>
                  </slot>
                </template>
                <template #picker-input-start="{value, changeHandler, editable}">
                  <slot name="input-start" v-bind:label="labelStart" v-bind:value="value"
                        v-bind:changeHandler="changeHandler" v-bind:editable="editable">
                    <v-text-field v-if="shape === 'underline'" :label="labelStart" v-model="value"
                                  @input="changeHandler" :readonly="!editable" :rules="[rules.date]"></v-text-field>
                    <v-text-field v-else v-model="value" :label="labelStart" @input="changeHandler"
                                  :readonly="!editable" outlined dense hide-details
                                  :rules="[rules.date]"></v-text-field>
                  </slot>
                </template>
                <template #picker-input-end="{value, changeHandler, editable}">
                  <slot name="input-end" v-bind:label="labelEnd" v-bind:value="value"
                        v-bind:changeHandler="changeHandler" v-bind:editable="editable">
                    <v-text-field v-if="shape === 'underline'" :label="labelEnd" v-model="value" @input="changeHandler"
                                  :readonly="!editable" :rules="[rules.date]"></v-text-field>
                    <v-text-field v-else v-model="value" :label="labelEnd" @input="changeHandler" :readonly="!editable"
                                  outlined dense hide-details :rules="[rules.date]"></v-text-field>
                  </slot>
                </template>
              </v-md-date-range-picker>
              </div>
            `,

            props: {
                multiple: {
                    type: Boolean,
                    default: false
                },
                editable: {
                    type: Boolean,
                    default: false
                },
                classes: {
                    type: Array,
                    default: () => []
                },
                type: {
                    type: String,
                    default: "calendar"
                },
                format: {
                    type: String,
                    default: "date"
                },
                start: {
                    type: String    //2020-01-01
                },
                end: {
                    type: String    //2020-01-02
                },
                minYear: {
                    type: String,
                    default: '2010'
                },
                maxYear: {
                    type: String,
                    default: '2030'
                },
                label: {
                    type: String,
                    default: "기간"
                },
                'labelStart': {
                    type: String,
                    default: '시작일'
                },
                'labelEnd': {
                    type: String,
                    default: '종료일'
                },
                shape: {
                    type: String,
                    default: 'outlined'
                },
                presets: {
                    type: Object,
                    default: function () {
                        return {
                            calendar: [
                                {
                                    label: this.$t('calendar.today'),
                                    range: mercury.base.util.getDateRange(0, 0),
                                },
                                {
                                    label: this.$t('calendar.yesterday'),
                                    range: mercury.base.util.getDateRange(1, 1),
                                },
                                {
                                    label: this.$t('calendar.7daysAgo'),
                                    range: mercury.base.util.getDateRange(6, 0),
                                },
                                {
                                    label: this.$t('calendar.30daysAgo'),
                                    range: mercury.base.util.getDateRange(29, 0),
                                },
                                {
                                    label: this.$t('calendar.thisMonth'),
                                    //range: mercury.base.util.getDateRange(0, 0, 'month'),
                                    range: [
                                        moment().set('date', 1),
                                        moment()
                                    ]
                                },
                                {
                                    label: this.$t('calendar.1monthAgo'),
                                    range: mercury.base.util.getDateRange(1, 1, 'month'),
                                }
                            ],
                            month: [
                                {
                                    label: '이번달',
                                    range: mercury.base.util.getDateRange(0, 0, 'month'),
                                },
                                {
                                    label: '지난달',
                                    range: mercury.base.util.getDateRange(1, 1, 'month'),
                                },
                                {
                                    label: '올해',
                                    range: mercury.base.util.getDateRange(0, 0, 'year'),
                                },
                                {
                                    label: '작년',
                                    range: mercury.base.util.getDateRange(1, 1, 'year'),
                                }
                            ]
                        };
                    }
                }
            },
            data: function () {
                return {
                    dModel: {
                        start: null,
                        end: null
                    },
                    locale: {
                        customRangeLabel: '직접선택',
                        totalRangeLabel: '전체',
                        format: 'YYYY-MM-DD'
                    },
                    rules: {
                        dateRange: value => value.replace(/[^0-9]/g, '').length === 16 || 'Invalid dateRange.',
                        date: value => value.replace(/[^0-9]/g, '').length === 8 || 'Invalid date.'
                    }
                }
            },
            created() {
                const start = this.start || moment({year: this.minYear}).startOf('year').format(this.locale.format)
                const end = this.end || moment({year: this.maxYear}).endOf('year').format(this.locale.format)

                Object.assign(this.dModel, {
                    start: start,
                    end: end
                });

                if (this.format === 'datetime') {
                    this.$emit("update:start", moment(start, this.locale.format).format('YYYY-MM-DD 00:00:00'));
                    this.$emit("update:end", moment(end, this.locale.format).format('YYYY-MM-DD 23:59:59'));
                }
            },
            watch: {
                "dModel": {
                    handler: function (data) {
                        if (this.format === 'datetime') {
                            this.$emit("update:start", moment(data.start).format('YYYY-MM-DD 00:00:00'));
                            this.$emit("update:end", moment(data.end).format('YYYY-MM-DD 23:59:59'));
                        } else {
                            this.$emit("update:start", moment(data.start).format(this.locale.format));
                            this.$emit("update:end", moment(data.end).format(this.locale.format));
                        }
                    },
                    deep: true
                },
                "start": {
                    handler: function (date) {
                        this.$nextTick(() => {
                            this.dModel.start = moment(date, this.locale.format).toDate();
                            if (this.format === 'datetime') {
                                this.$emit("update:start", moment(date).format('YYYY-MM-DD 00:00:00'));
                            } else {
                                this.$emit("update:start", date);
                            }
                        });
                    }
                },
                "end": {
                    handler: function (date) {
                        this.$nextTick(() => {
                            this.dModel.end = moment(date, this.locale.format).toDate();
                            if (this.format === 'datetime') {
                                this.$emit("update:end", moment(date).format('YYYY-MM-DD 23:59:59'));
                            } else {
                                this.$emit("update:end", date);
                            }
                        });
                    }
                }
            }
        });

        Vue.component('mp-date-picker', {
            template: `
              <div>
              <v-menu max-width="290px" min-width="auto" transition="scale-transition" offset-y ref="menu" :left="left" :right="right"
                      v-model="menu" :disabled="disabled"
                      :close-on-content-click="!confirm"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model.sync="text" :label="label" :readonly="readonly" :disabled="disabled" v-bind="{...attrs, ...properties}" v-on="on"
                                @change="apply" :clearable="clearable" :rules="rules" :class="['mp-date-picker', className]">
                    <template v-slot:append-outer v-if="$slots['append-outer']">
                      <slot name="append-outer"></slot>
                    </template>
                    <template v-slot:append v-if="$slots['append']">
                      <slot name="append"></slot>
                    </template>
                  </v-text-field>
                </template>
                <template v-if="type==='range'">
                  <v-date-picker v-model.sync="dModel" :min="min" :max="max" :locale="locale" no-title scrollable range>
                    <template v-if="confirm">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="cancel()">Cancel</v-btn>
                      <v-btn text color="primary" @click="apply(dModel)">OK</v-btn>
                    </template>
                  </v-date-picker>
                </template>
                <template v-else>
                  <v-date-picker v-model.sync="dModel" :min="min" :max="max" :locale="locale" no-title scrollable :type="type" :allowed-dates="allowedDates">
                    <template v-if="confirm">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="cancel()">Cancel</v-btn>
                      <v-btn text color="primary" @click="apply(dModel)">OK</v-btn>
                    </template>
                  </v-date-picker>
                </template>
              </v-menu>
              </div>
            `,
            props: {
                label: String,
                min: {
                    type: String,
                    default: null
                },
                max: {
                    type: String,
                    default: null
                },
                locale: {
                    type: String,
                    default: 'ko-kr'
                },
                type: {
                    type: String,
                    default: 'date'
                },
                value: {
                    type: String,
                    default: function () {
                        if (this.type === 'date') {
                            return moment().format('YYYY-MM-DD');
                        } else if (this.type === 'month') {
                            return moment().format('YYYY-MM');
                        } else if (this.type === 'range') {
                            return moment().format('YYYY-MM-DD') + ' ~ ' + moment().format('YYYY-MM-DD');
                        }
                    }
                },
                className: String,
                readonly: {
                    type: Boolean,
                    default: false
                },
                disabled: {
                    type: Boolean,
                    default: false
                },
                clearable: {
                    type: Boolean,
                    default: false
                },
                confirm: {
                    type: Boolean,
                    default: true
                },
                shape: {
                    type: String,
                    default: 'outlined'
                },
                left: {
                    type: Boolean,
                    default: false
                },
                right: {
                    type: Boolean,
                    default: false
                },
                allowBlank: {
                    type: Boolean,
                    default: false
                },
                allowedDates: {
                    type: Function,
                    default: () => {
                        return true
                    }
                },
                rules: {

                },
                // Other v-text-field properties
                properties: {
                    type: Object,
                    default: function () {
                        return {};
                    }
                }
            },
            data: function () {
                return {
                    menu: false,
                    dModel: undefined,
                    text: undefined
                }
            },
            mounted() {
                this.reset();
            },
            watch: {
                "dModel": {
                    handler: function (data) {
                        if (!this.confirm) {
                            this.apply(data);
                        }
                    },
                    deep: true
                },
                'value': {
                    immediate: true,
                    handler() {
                        this.reset()
                    }
                }
            },
            methods: {
                reset() {
                    this.text = this.value;
                    if (this.type === 'range') {
                        this.dModel = this.value.split(' ~ ');//array 필요
                    } else {
                        this.dModel = this.value;
                    }
                },
                cancel() {
                    this.reset();
                    this.menu = false;
                },
                apply(data) {

                    let dModel, text;

                    if (this.type === 'range') {
                        if (typeof data === 'string') {
                            data = data.split(' ~ ');
                        }

                        dModel = data.sort();//반대 클릭시 시작 종료 정렬
                        text = dModel.join(' ~ ');
                    } else {
                        dModel = data;
                        text = data;
                    }

                    if (dModel == this.dModel && text == this.text) {
                        return
                    }

                    this.dModel = dModel;
                    this.text = data

                    if (!this.validateDate(data)) {
                        return this.reset();
                    }

                    this.$emit("input", this.text); //value update
                    this.$emit("change", this.text);
                    this.menu = false;
                },
                validateDate(date) {

                    if (this.allowBlank && !date) {
                        return true;
                    }

                    if (typeof date === 'string') {
                        return moment(date).isValid();
                    } else {//array range
                        const dataLen = date.length;
                        if (dataLen !== 2) {
                            return false;
                        }

                        for (let i = 0; i < dataLen; i++) {
                            if (!moment(date[i]).isValid()) {
                                return false;
                            }
                        }

                        return true;
                    }
                }
            },
            created() {
                if (this.shape !== 'underline') {
                    this.properties.outlined = true
                    this.properties.dense = true
                    this.properties.hideDetails = this.rules == null
                }
            }
        })

        Vue.component('mp-time-picker', {
            template: `
              <div>
              <v-menu max-width="290px" min-width="auto" transition="scale-transition" offset-y :right="right" :left="left" ref="menu"
                      v-model="menu"
                      :close-on-content-click="!confirm"
              >

                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model.sync="text" :label="label" :readonly="readonly" v-bind="attrs" v-on="on"
                                @change="apply" :outlined="outlined" dense hide-details :prepend-icon="icon"></v-text-field>
                </template>
                <v-time-picker v-model.sync="dModel" :locale="locale"  scrollable>
                  <template v-if="confirm">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="cancel()">Cancel</v-btn>
                    <v-btn text color="primary" @click="apply(dModel)">OK</v-btn>
                  </template>
                </v-time-picker>

              </v-menu>
              </div>
            `,
            props: {
                label: String,
                locale: {
                    type: String,
                    default: 'ko-kr'
                },
                value: {
                    type: String,
                    default: function () {
                        return moment().format('HH:mm');
                    }
                },
                outlined: {
                  type: Boolean,
                  default: true
                },
                readonly: {
                    type: Boolean,
                    default: false
                },
                confirm: {
                    type: Boolean,
                    default: true
                },
                left: {
                    type: Boolean,
                    default: false
                },
                right: {
                    type: Boolean,
                    default: false
                },
                icon: {
                    type: String,
                    default: null
                }
            },
            data: function () {
                return {
                    menu: false,
                    dModel: undefined,
                    text: undefined
                }
            },
            mounted() {
                this.reset();
            },
            methods: {
                reset() {
                    this.text = this.value;
                    this.dModel = this.value;
                },
                cancel() {
                    this.reset();
                    this.menu = false;
                },
                apply(data) {
                    this.dModel = data;
                    this.text = data;
                    this.$emit("input", this.text); //value update
                    this.$emit("change", this.text);
                    this.menu = false;
                },
            }
        });

        Vue.component('mp-view', {
            template: `
              <div v-if="dView.mode === 'sheet'">
              <v-sheet v-if="dView.show">
                <slot name="head" v-bind:edit="dView.edit"></slot>
                <slot name="body" v-bind:edit="dView.edit"></slot>
                <v-card-actions>
                  <slot name="control.prepend" v-bind:edit="dView.edit"></slot>
                  <v-spacer></v-spacer>
                  <slot name="control" v-bind:edit="dView.edit"></slot>
                </v-card-actions>
              </v-sheet>
              </div>
              <div v-else-if="dView.mode === 'dialog'">
              <v-dialog v-model="dView.show" :max-width="dView.width" scrollable @keydown.esc="closeDialog()" :fullscreen="isMobile" :persistent="isMobile"
                        @click:outside="()=>{
                          if(!isMobile) {
                            closeDialog()
                          }
                        }" overlay-color="#fff">
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ dView.title }}</span>
                    <v-card-subtitle v-if="dView.subtitle" class="pt-0 pb-0 mt-1">
                      {{dView.subtitle}}
                    </v-card-subtitle>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeDialog()">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <slot name="head" v-bind:edit="dView.edit"></slot>
                    <slot name="body" v-bind:edit="dView.edit"></slot>
                  </v-card-text>
                  <v-card-actions>
                    <slot name="control.prepend" v-bind:edit="dView.edit"></slot>
                    <v-spacer></v-spacer>
                    <slot name="control" v-bind:edit="dView.edit"></slot>
                    <slot name="close" v-bind:edit="dView.edit">
                      <v-btn color="secondary" text @click="closeDialog()" outlined raised>
                        <v-icon>mdi-close mdi-18px</v-icon>
                        닫기
                      </v-btn>
                    </slot>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              </div>
            `,
            props: {
                view: Object
            },
            data: function () {
                return {
                    dView: Object.assign({
                        mode: 'sheet',  //  sheet || dialog
                        show: false,
                        edit: false,
                        width: '600px',
                        title: '제목'
                    }, this.view)
                }
            },
            computed: {
                isMobile() {
                    return !!(new MobileDetect(window.navigator.userAgent).mobile())
                }
            },
            watch: {
                "view": {
                    handler: function (view) {
                        Object.assign(this.dView, view);
                    },
                    deep: true
                }
            },
            methods: {
                closeDialog() {
                    this.dView.show = false;
                    this.$emit("update:view", this.dView);
                }
            }
        });

        Vue.component('mp-dialog', {
            template: `
              <v-dialog :retain-focus="false" :fullscreen="fullscreen" v-model="dialog.visible"
                        :width="dialog.width||1024" scrollable :persistent="persistent" overlay-color="#fff"
                        :no-click-animation="true"
                        @keydown.esc="onClickEsc"
                        @click:outside="onClickOutside">
              <v-card>
                <v-card-title class="headline lighten-2">
                  {{ dialog.title }}
                  <v-card-subtitle v-if="dialog.subtitle" class="pt-0 pb-0 mt-1">
                    {{dialog.subtitle}}
                  </v-card-subtitle>
                  <slot name="titleExtension"></slot>
                  <v-spacer></v-spacer>
                  <v-btn v-if="closable" icon @click="dialog.close()">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text v-if="dialog.visible">
                  <template v-if="$slots['body']">
                    <slot name="body"></slot>
                  </template>
                  <template v-else>
                    <component ref="dialog-component" :is="dialog.component" v-bind.sync="dialog.props" :dialog="dialog"
                               @close="dialog.close()" @emit="$emit('emit', $event)" @setVisibleActions="setVisibleActions"></component>                  
                  </template>
                </v-card-text>
                <v-card-actions v-if="visibleActions">
                  <template v-if="actions() && actions().left">
                    <template v-for="(item, index) in actions().left">
                      <span v-if="item.label" :key="item.name+index" class="text-caption" :class="{'danger--text': item.color === 'danger'}">
                        {{item.label}}
                      </span>
                      <v-btn v-else :key="item.name+index" :color="item.color" text
                             @click="invokeComponentMethod(item)" outlined raised>{{ item.name }}
                      </v-btn>
                      
                    </template>
                    
                  </template>
                  <v-spacer></v-spacer>
                  <template v-if="actions() && actions().right">
                    <v-btn v-for="(item, index) in actions().right" :key="item.name+index" :color="item.color" text
                           @click="invokeComponentMethod(item)" outlined raised>{{ item.name }}
                    </v-btn>
                  </template>
                  <v-btn v-if="closable" color="grey" text @click="dialog.close()" outlined raised>Close</v-btn>
                </v-card-actions>
              </v-card>
              </v-dialog>
            `,
            data: () => ({
                visibleActions: true
            }),
            props: {
                closable: {
                    type: Boolean,
                    default: true
                },
                escClose: {
                    type: Boolean,
                    default: true
                },
                fullscreen: {
                    type: Boolean,
                    default: false
                },
                persistent: {
                    type: Boolean,
                    default: true
                },
                dialog: Object
                /*
                {
                    title: '플랫폼 관리',
                    width: 1024
                    visible: false,
                    reload: true,
                    component: MpAdvertiserPlatform,
                    props: {advertiser: {}},
                    close: () => {this.dialog.platform.visible = false;},
                    actions:{
                        left:[
                            {
                                name:'복사',
                                color: 'green',
                                method: 'callInner'
                            }
                        ],
                        right:[
                            {
                                name:'저장',
                                color: 'danger',
                                method: 'callInner'
                            }
                        ]
                    }
                }
                */
            },
            methods: {
                actions() {
                    if (isFunction(this.dialog.actions)) {
                        return this.dialog.actions();
                    }

                    return this.dialog.actions;
                },
                setVisibleActions(bool = true) {
                    this.visibleActions = bool;
                },
                onClickOutside() {
                    if (!this.persistent) {
                        this.dialog.close()
                    }
                },
                onClickEsc() {
                    if (!this.escClose) {
                        return;
                    }
                    this.dialog.close()
                },
                invokeComponentMethod(btn) {

                    if(typeof btn.method === 'function') {
                        return btn.method()
                    }

                    if (typeof this.$refs['dialog-component'][btn.method] === 'function') {
                        this.$refs['dialog-component'][btn.method](this.dialog)
                    } else {
                        let msg = this.dialog.component.name + ' component 의 ' + btn.method + ' 가 정의되지 않았거나 Function 이 아닙니다.';
                        this.mercury.base.lib.notify({message: msg, type: 'error'})
                    }
                }
            }
        });

        Vue.component('mp-boolean-yn', {
            template: `
              <span v-if="mode === 'chip'">
                <v-chip color="green" v-if="value === 'Y'" small>사용</v-chip>
                <v-chip color="gray" v-else small>미사용</v-chip>
            </span>
              <span v-else-if="mode === 'boolean'">
                <v-chip color="green" v-if="value === 'true'" small>예</v-chip>
                <v-chip color="gray" v-else small>아니오</v-chip>
            </span>
              <span v-else>
                <span v-if="value === 'Y'">Y</span>
                <span v-else>X</span>
            </span>
            `,
            props: {
                value: String,
                mode: {
                    type: String,
                    default: 'chip'
                }
            }
        });

        Vue.component('mp-number-field', {
            template: `
              <v-text-field
                  ref="input"
                  :class="['mp-number-field', className]"
                  v-model="innerValue"
                  v-bind:label="label"
                  v-bind:placeholder="placeholder"
                  v-bind:readonly="readonly"
                  v-bind:disabled="disabled"
                  v-bind:outlined="outlined"
                  v-bind:dense="dense"
                  v-bind:hide-details="hideDetails"
                  v-bind:error="error"
                  v-bind:error-messages="errorMessages"
                  v-bind:rules="rules"
                  v-bind:clearable="clearable"
                  v-bind:prefix="options.prefix"
                  v-bind:suffix="options.suffix"
                  v-bind:prepend-icon="prependIcon"
                  v-bind:prepend-inner-icon="prependInnerIcon"
                  v-bind:append-icon="appendIcon"
                  v-bind:append-outer-icon="appendOuterIcon"
                  v-bind="properties"
                  v-on:keypress="keyPress"
                  v-on:blur="onBlur"
                  v-on:focus="onFocus"
                  @click="clickNumberField($event)"
                  @change="changeValue"
              >
                  <template v-slot:append-outer>
                    <slot name="append-outer"></slot>
                  </template>
                  <template v-slot:append>
                    <slot name="append"></slot>
                  </template>
                  <template v-slot:prepend>
                    <slot name="prepend"></slot>
                  </template>
                  <template v-slot:prepend-inner>
                    <slot name="prepend-inner"></slot>
                  </template>
                  <template v-slot:message="{message, key}">
                    <slot name="message" v-bind="{message, key}"></slot>
                  </template>
              </v-text-field>
            `,
            model: {prop: "value", event: "input"},
            props: {
                value: {
                    // type: String,
                    type: [String, Number],
                    default: "0"
                },
                label: {
                    type: String,
                    default: ""
                },
                placeholder: {
                    type: String,
                    default: undefined
                },
                readonly: {
                    type: Boolean,
                    default: false
                },
                dense: {
                    type: Boolean,
                    default: false
                },
                error: {
                    type: Boolean,
                    default: false
                },
                hideDetails: {
                    type: [Boolean, String],
                    default: false
                },
                errorMessages: {
                    type: [Array, String],
                    default: () => []
                },
                rules: {
                    type: [Array, String],
                    default: () => []
                },
                disabled: {
                    type: Boolean,
                    default: false
                },
                outlined: {
                    type: Boolean,
                    default: false
                },
                clearable: {
                    type: Boolean,
                    default: false
                },
                prependIcon: {
                    type: String,
                    default: ""
                },
                prependInnerIcon: {
                    type: String,
                    default: ""
                },
                appendIcon: {
                    type: String,
                    default: ""
                },
                appendOuterIcon: {
                    type: String,
                    default: ""
                },
                backgroundColor: {
                    type: String,
                    default: "white"
                },
                valueWhenIsEmpty: {
                    type: String,
                    default: "" // "0" or "" or null
                },
                valueOptions: {
                    type: Object,
                    default: function () {
                        return {
                            min: 0,
                            minEvent: "SetValueMin"
                        };
                    }
                },
                options: {
                    type: Object,
                    default: function () {
                        return {};
                    }
                },
                // Other v-text-field properties
                properties: {
                    type: Object,
                    default: function () {
                        return {};
                    }
                },
                className: String
            },
            data: function() {
                return {
                    innerValue: null,
                    innerOptions: {
                        locale: "ko-KR",
                        prefix: "",
                        suffix: "",
                        length: 13,
                        precision: 0
                    }
                }
            },
            computed: {
                cmpValue: {
                    get: function () {
                        return this.value !== null && this.value !== ""
                            ? this.humanFormat(this.value.toString())
                            : this.valueWhenIsEmpty;
                    },
                    set: function (newValue) {
                        this.$emit("input", this.machineFormat(newValue));
                        this.$emit('change', this.machineFormat(newValue))
                    }
                }
            },
            methods: {
                humanFormat: function (number) {
                    number = isString(number) ? number.replace(/,/g, '') : number

                    if (isNaN(number)) {
                        number = "";
                    } else {
                        // number = Number(number).toLocaleString(this.innerOptions.locale, {maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'BRL'});
                        number = Number(number).toLocaleString(this.innerOptions.locale, {
                            maximumFractionDigits: this.innerOptions.precision,
                            minimumFractionDigits: 0
                        });
                    }
                    return number;
                },
                machineFormat(number) {
                    if(number === '0' || number === 0) {
                        return number;
                    }

                    if (number !== '' && number !== null && number !== undefined) {
                        number = this.cleanNumber(number);
                        // Ajustar quantidade de zeros à esquerda
                        number = number.padStart(parseInt(this.innerOptions.precision) + 1, "0");
                        // Incluir ponto na casa correta, conforme a precisão configurada
                        number =
                            number.substring(
                                0,
                                number.length - parseInt(this.innerOptions.precision)
                            ) +
                            "." +
                            number.substring(
                                number.length - parseInt(this.innerOptions.precision),
                                number.length
                            );
                        if (isNaN(number)) {
                            number = this.valueWhenIsEmpty;
                        }
                    } else {
                        number = this.valueWhenIsEmpty;
                    }
                    if (this.innerOptions.precision === 0) {
                        number = this.cleanNumber(number);
                    }
                    return number;
                },
                keyPress($event) {
                    // console.log($event.keyCode); //keyCodes value
                    let keyCode = $event.keyCode ? $event.keyCode : $event.which;

                    if (keyCode == 45) {
                        if(typeof this.innerValue === 'string' && this.innerValue.includes('-')) {
                            $event.preventDefault();

                        }
                        return
                    }

                    // 소수점(.)이 두번 이상 나오지 못하게
                    if (/^\d*[.]\d*$/.test(this.innerValue) && keyCode === 46) {
                        $event.preventDefault();
                    }

                    // if (keyCode < 48 || keyCode > 57) {
                    if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                        // 46 is dot
                        $event.preventDefault();
                    }

                    if (this.targetLength()) {
                        $event.preventDefault();
                    }

                    if (this.innerOptions.precision > 0 && this.innerValue.includes('.') && this.innerValue.split('.')[1].length >= this.innerOptions.precision) {
                        $event.preventDefault();
                    }
                },
                // Retira todos os caracteres não numéricos e zeros à esquerda
                cleanNumber: function (value) {
                    if(value === '0' || value === 0) {
                        return value;
                    }
                    let result = "";
                    if (value !== '' && value !== null && value !== undefined) {
                        let flag = false;
                        let arrayValue = value.toString().split("");
                        for (var i = 0; i < arrayValue.length; i++) {
                            if (this.isInteger(arrayValue[i]) || (i == 0 && arrayValue[i] == '-')) {
                                if (!flag) {
                                    // Retirar zeros à esquerda
                                    if (arrayValue[i] !== "0") {
                                        result = result + arrayValue[i];
                                        flag = true;
                                    }
                                } else {
                                    result = result + arrayValue[i];
                                }
                            }
                        }
                    }
                    return result;
                },
                isInteger(value) {
                    let result = false;
                    if (Number.isInteger(parseInt(value))) {
                        result = true;
                    }
                    return result;
                },
                targetLength() {
                    if (
                        Number(this.cleanNumber(this.value).length) >=
                        Number(this.innerOptions.length)
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                },
                onFocus(){
                  this.$emit('focus')
                },
                onBlur() {
                    if (
                        this.value === null || this.value.length === 0 ||
                        parseFloat(this.value) <= this.valueOptions.min
                    ){
                        this.$emit(
                            this.valueOptions.minEvent || "SetValueMin",
                            this.valueOptions.min
                        );
                    }
                    if (
                        this.valueOptions.max &&
                        parseFloat(this.value) >= this.valueOptions.max
                    ){
                        this.$emit(
                            this.valueOptions.maxEvent || "SetValueMax",
                            this.valueOptions.max
                        );
                    }

                    this.innerValue = this.humanFormat(this.innerValue.toString())
                    this.changeValue()
                },
                clickNumberField($event){
                    try{
                        $event.target.select()
                    }catch(e){
                        //ignore
                    }
                },
                changeValue() {
                    this.$emit("input", this.innerValue.replace(/,/g, ''))
                    this.$emit('change', this.innerValue.replace(/,/g, ''))
                }
            },
            created() {
                this.innerOptions = assignIn(this.innerOptions, this.options)
                this.innerValue = this.humanFormat(this.value)
            },
            watch: {
                value: {
                    handler() {
                        this.innerValue = this.humanFormat(this.value)
                    }
                }
            }
        });

        //form 내에 인풋이 한개 밖에 없는 경우 enter를 누르면 자동 form submit이 발생하는 걸 막기 위해 빈 인풋을 추가
        Vue.component('mp-prevent-submit-input', {
            template: `<input type="text" style="position:absolute;width:0 !important;height:0 !important;line-height: 0 !important;"/>`,
        });


        Vue.component('mp-js-tree', {
            template: `
              <div :ref="refName" class="js-tree-wrapper"></div>
            `,
            props: {
                url: String,
                refName: {
                    type: String,
                    default: 'js-tree'
                },
                changed: Function,
                created: Function,
                renamed: Function,
                deleted: Function,
                moved: Function,
                multiple: {
                    type: Boolean,
                    default: false
                },
                dnd: {
                    type: Boolean,
                    default: false
                },
                state: {
                    type: Boolean,
                    default: false
                },
                checkCallback: {
                    type: Function,
                    default: function (operation, node, node_parent, node_position, more) {
                        return true;
                    }
                },
                searchCallback: {
                    type: Function,
                    default: function (str, node) {

                    }
                },
                types: {
                    type: Object,
                    default: function () {
                        return {
                            'default': {
                                'icon': 'mdi mdi-folder text-primary'
                            },
                            'folder': {
                                'icon': 'mdi mdi-folder text-primary'
                            },
                            'file': {
                                'icon': 'mdi mdi-file  text-primary'
                            }
                        }
                    }
                }
            },
            data: function () {
                return {
                    instance: undefined
                };
            },
            created() {

            },
            async mounted() {
                await this.fetchData();
            },
            computed: {},
            watch: {},
            methods: {
                async fetchData() {
                    const options = {
                        multiple: this.multiple,
                        dnd: this.dnd,
                        state: this.state,
                        checkCallback: this.checkCallback,
                        searchCallback: this.searchCallback,
                        types: this.types
                    };

                    const plugins = ['themes', 'json_data', 'ui', 'crrm', 'types', 'search'/*, "wholerow"*//*, "contextmenu"*/];
                    if (options['dnd']) {
                        plugins.push('dnd');
                    }
                    if (options['state']) {
                        plugins.push('state');
                    }


                    const _this = this;

                    const treeData = await this.xAjax({
                        url: this.url
                    });

                    const jsTreeOptions = {
                        'plugins': plugins,
                        'themes': {
                            'responsive': false
                        },
                        core: {
                            multiple: options.multiple,
                            data: treeData,
                            check_callback: function (operation, node, node_parent, node_position, more) {
                                //return _this.$emit('check-callback', operation, node, node_parent, node_position, more, _this.instance);
                            }
                        },
                        'state': {
                            'key': 'ubicus-base-tree-' + this.refName
                        },
                        'types': options.types,
                        search: {
                            case_insensitive: false,
                            show_only_matches: true,
                            show_only_matches_children: false,
                            search_callback: function (str, node) {
                                let keyword = str.trim();
                                if (keyword === '') {
                                    return true;
                                }

                                return node.text.toLowerCase().includes(keyword.toLowerCase());
                            }
                        }
                    };

                    const $refTree = $(this.$refs[this.refName]);
                    $refTree.jstree(jsTreeOptions).on('changed.jstree', function (e, data) {
                        _this.$emit('changed', e, data, _this.instance);
                    }).on('create_node.jstree', function (e, data) {
                        _this.$emit('created', e, data, _this.instance);
                    }).on('rename_node.jstree', function (e, data) {
                        _this.$emit('renamed', e, data, _this.instance);
                    }).on('delete_node.jstree', function (e, data) {
                        _this.$emit('deleted', e, data, _this.instance);
                    }).on('move_node.jstree', function (e, data) {
                        let parentNode = null;

                        $refTree.find('li .jstree-anchor').each(function() {
                            let itemId = $(this)[0].id.replace('_anchor', '')
                            if (itemId === data.parent) {
                                parentNode = $(this);
                                return false; // 반복 중지
                            }
                        });

                        _this.mercury.base.lib.confirm(data.node.text + "를 " + parentNode.text() +"로  이동하시겠습니까?").then(result => {
                            if (result.isConfirmed) {
                                _this.$emit('moved', e, data, _this.instance);
                            }else{
                                _this.instance.refresh();
                            }
                        })
                    });

                    this.instance = $refTree.jstree(true);
                },
                async refresh(nodeId) {
                    if (nodeId !== undefined) {
                        this.instance.refresh_node(nodeId);
                    } else {
                        this.instance.settings.core.data = await this.xAjax({
                            url: this.url
                        });
                        this.instance.refresh();
                    }
                },
                search(searchText) {
                    this.instance.search(`${searchText}`);
                },
                showAll() {
                    this.instance.show_all();
                },
                renameNode(node, text) {
                    this.instance.rename_node(node, text);
                },
                getInstance() {
                    return this.instance;
                },
                getPath(node) {
                    const nodePath = '<i class="text-primary">' + node.text + '</i>';
                    if (node.parent === '#') {
                        return nodePath;
                    } else {
                        const prePath = this.instance.get_path(node.parent, ' > ');
                        return '<i>' + prePath + '</i>' + '<i class="text-danger"> > </i>' + nodePath;
                    }
                },
                deselectAll() {
                    this.instance.deselect_all();
                },
                selectNode(nodeId) {
                    this.instance.deselect_all();
                    this.instance.select_node(nodeId);
                },
                getSelectedNode(isFull) {//boolean
                    return this.instance.get_selected(isFull);
                },
                getNode(nodeId) {
                    return this.instance.get_node(nodeId);
                },
                getTopNode(node) {
                    if (typeof node === 'string') {//id
                        node = this.instance.get_node(node);
                    }

                    let parent = node;
                    let i = 0;
                    while (parent.parent !== '#') {
                        parent = this.instance.get_node(parent.parent);
                        i++;

                        if (i > 10) {
                            console.log('loop count over ' + i);
                            break;
                        }
                    }
                    return parent;
                },
                expandAll() {
                    this.instance.open_all();
                },
                collapseAll() {
                    this.instance.close_all();
                }
            }
        });
    }
}

export default MercuryGlobal;
