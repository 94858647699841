// Polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import Vuex from 'vuex'
import App from './App'
import Vuetify from 'vuetify'
import VueRouter from 'vue-router'
import LoadScript from 'vue-plugin-load-script'

import MercuryGlobal from '@plugins/MercuryGlobal'

import MobileDetect from 'mobile-detect'

import 'jstree'
import 'jstree/dist/themes/default/style.min.css'

import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'toastr/toastr.scss'
import 'formulize/dist/formulize.css'
import {ObserveVisibility} from 'vue-observe-visibility'

import VueDateRangePicker from 'vue-date-range-picker'

import DatetimePicker from 'vuetify-datetime-picker'

import Sortable from 'vue-sortable'
import {
    xAjax,
    API_HOST,
    clearAuthorization,
    ERROR_PAGE,
    menuToRoutes,
    excludeMenuByGroup
} from '@plugins/MercuryBaseLib'
import baseStore from '@store/baseStore'
import campaignStore from '@store/campaignStore'
import Router from 'vue-router'
import i18n from '@locales/i18n'

import 'vue-multiselect/dist/vue-multiselect.min.css'

import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {getAppVersion, setCountTicketCount, setMyApproves} from '@plugins/CapsuleCommon'
import VueClipboard from 'vue-clipboard2'

import VuetifyMoney from 'vuetify-money'
import {VueMasonryPlugin} from 'vue-masonry'

import * as Sentry from '@sentry/vue'
import {BrowserTracing} from '@sentry/tracing'
import {connect, send} from '@/websocket'
import {subscribeTicketUser} from '@/websocket/ticket'
import {ticketEvent} from '@/event'
import {longClickDirective} from 'vue-long-click'
import {subscribeApproveUser} from '@/websocket/approve'

Vue.use(VuetifyMoney)

Vue.use(DatetimePicker)

Vue.use(VueMasonryPlugin)
Vue.use(VueClipboard)

Vue.use(LoadScript)
Vue.use(Sortable)
Vue.use(VueDateRangePicker)
Vue.use(Vuex)
Vue.use(Vuetify)
Vue.use(Router)
Vue.use(MercuryGlobal)
Vue.directive('observe-visibility', ObserveVisibility)

const longClickInstance = longClickDirective({delay: 400, interval: 30})
Vue.directive('longclick', longClickInstance)

Vue.directive('role-check', (el, binding, vnode) => {

    const roles = typeof binding.value === 'string' ? [binding.value] : binding.value

    if (!roles.includes('ADMIN')) {
        roles.push('ADMIN')
    }

    if (!vnode.context.hasAnyRole(roles)) {

        // replace HTMLElement with comment node
        const comment = document.createComment(' ')
        Object.defineProperty(comment, 'setAttribute', {
            value: () => undefined
        })
        vnode.elm = comment
        vnode.text = ' '
        vnode.isComment = true
        vnode.context = undefined
        vnode.tag = undefined
        vnode.data.directives = undefined

        if (vnode.componentInstance) {
            vnode.componentInstance.$el = comment
        }

        if (el.parentNode) {
            el.parentNode.replaceChild(comment, el)
        }
    }
})

Vue.config.productionTip = false

const projectName = 'Capsule'
const initBaseData = {};


(async function () {

    const $store = new Vuex.Store({
        modules: {// 네임스페이스:store
            base: baseStore,
            campaign: campaignStore
        },
        strict: true // 전역
    })

    xAjax({
        url: '/base/users/me'
    }).then(async resp => {

        initBaseData['user'] = resp

        const loadScriptArr = [
            Vue.loadScript(API_HOST + `/base/codes/scripts/${resp.clientId}`)//필수
        ]
        await Promise.all(loadScriptArr)

        /*if(resp.roles.includes('SYSTEM_MANAGER')){
            const [clients, models] = await Promise.all([
                xAjax({url: '/clientsAll'}),
                xAjax({url: '/main/analysis/reports/columns'})
            ])

            initBaseData['clients'] = clients;
        }*/

        const [clients, organizationTree, adPlatforms, unmanagedPlatforms] = await Promise.all([
            xAjax({url: '/clients/all'}),
            xAjax({url: '/base/organizations/tree'}),
            xAjax({url: '/adPlatforms/all'}),
            xAjax({url: '/platforms/unmanaged/all'})
        ])


        initBaseData['organizationTree'] = organizationListToTree(organizationTree)
        initBaseData['clients'] = clients

        const _adPlatforms = {}
        const _adConfigs = {}

        adPlatforms.forEach(adPlatform => {
            _adPlatforms[adPlatform.name] = adPlatform

            adPlatform.adConfigs.forEach(adConfig => {
                adConfig.directInputKey = adConfig.name === '직접입력'
                _adConfigs[adConfig.id] = adConfig
            })

            adPlatform.adPlatformProducts.forEach(adPlatformProduct => {
                adPlatformProduct.adConfigs.forEach(adConfig => {
                    adConfig.directInputKey = adConfig.name === '직접입력'
                    _adConfigs[adConfig.id] = adConfig
                })

            })
        })

        initBaseData['unmanagedPlatforms'] = unmanagedPlatforms
        initBaseData['adPlatforms'] = _adPlatforms
        initBaseData['adConfigs'] = _adConfigs

        const [activeEmployees] = await Promise.all([
            xAjax({url: '/base/users/activeEmployees'})
        ])

        console.log('activeEmployees', activeEmployees)

        initBaseData['activeEmployees'] = activeEmployees

        return xAjax({url: '/base/menu/getMenuList', usePrefixUrl: true, data: {menuId: projectName}})
    }).then(async resp => {
        const menus = resp
        const routes = [{
            name: projectName,
            label: projectName,
            path: '/',
            component: () => import(`@views/main/MpMain`),
            meta: {
                title: projectName
            }
        }]

        menuToRoutes(menus, routes, initBaseData['user'].roles)

        const profileMenu = []
        if (initBaseData['user'].roles.includes('SYSTEM_MANAGER')) {
            /*profileMenu.push({
                title:'Admin Page',
                icon:'mdi-shield-lock mdi-18px',
                click : function(){
                    location.href='/admin.html'
                }
            })*/
        }

        const router = new VueRouter({
            // mode: 'history',
            routes
        })

        console.info('process.env', process.env)

        initBaseData['enableSchedule'] = process.env.VUE_APP_ENABLE_SCHEDULE !== false && process.env.VUE_APP_ENABLE_SCHEDULE !== 'false'


        if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'production') {
            Sentry.setUser({
                email: initBaseData['user']['email'],
                username: initBaseData['user']['name'],
                id: initBaseData['user']['id']
            })
            Sentry.init({
                Vue,
                dsn: 'https://baf018dc861c455aa071d20ed2060157@sentry.mercuryproject.co.kr/5',
                integrations: [
                    new BrowserTracing({
                        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                        tracingOrigins: ['devcapsule.mercuryproject.co.kr', 'capsule.mercuryproject.com', /^\//]
                    })
                ],
                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                // We recommend adjusting this value in production
                tracesSampleRate: 1.0,
                logErrors: true
            })
        }



        // Facebook SDK init
        await xAjax({url: '/platform/facebook/sdkInfo'}).then(async resp => {
            const {appId, version, access_token, businessId} = resp

            await Vue.loadScript(process.env.VUE_APP_FACEBOOK_JAVASCRIPT_SDK_URL).then(() => {
                FB.init({
                    appId: appId,
                    version: version
                })

                $store.dispatch('campaign/setFacebookSdk', FB)
                $store.dispatch('campaign/setFacebookAccessToken', access_token)
                $store.dispatch('campaign/setFacebookBusinessId', businessId)
            })
        })


        addEventListener('beforeunload', (event) => {
            const updatedStructureGroups = $store.getters['campaign/getUpdatedStructureGroups']
            if (updatedStructureGroups === true) {
                event.preventDefault()
                return event.returnValue = '티켓 Structure가 수정되었습니다. 저장하지 않고 나가시겠습니까?'
            }
        }, {capture: true})


        /* eslint-disable no-new */
        new Vue({
            el: '#app',
            i18n,
            router: router,
            store: $store,
            vuetify: new Vuetify(),

            created() {

                this.$store.dispatch('base/initStore', initBaseData)
                setCountTicketCount($store)
                setMyApproves($store)
                connect({}, {
                    connect: frame => {
                        console.log('ws connect success frame', frame)
                    },
                    error: error => {
                        console.log('ws connect fail error', error)
                    },
                    onclose: () => {
                        console.log('ws connect closed')
                    }
                })

                document.addEventListener('visibilitychange', function (event) {
                    if (document.hidden) {
                        send('/pub/capsule/deactivateUser', {}, {})
                    } else {
                        send('/pub/capsule/activateUser', {}, {})
                    }
                })

                subscribeTicketUser(initBaseData['user'].id, (data) => {
                    console.log('change Ticket', data)
                    setCountTicketCount($store)
                    ticketEvent.$emit('ticketListReload')
                })

                subscribeApproveUser(initBaseData['user'].id, (data) => {
                    window.setTimeout(() => {
                        setMyApproves($store)
                    }, 2000)

                })

                if (new MobileDetect(window.navigator.userAgent).mobile()) {
                    if (!location.pathname.includes('m_index')) {
                        location.href = `/m_index.html${location.hash}`
                    }
                }

                getAppVersion(this.$store)


            },
            watch: {
                $route: {
                    immediate: true,
                    handler(to) {
                        if (to !== undefined) {
                            document.title = to.meta.title || projectName
                        }
                    }
                }
            },
            render: h => h(App, {
                props: {
                    templateMenus: menus,
                    logo: {
                        src: '/static/image/logo.png',
                        title: ' Capsule',
                        width: 25,
                        height: 20
                    },
                    profile: {
                        env: process.env.NODE_ENV === 'production' ? '' : 'DEV',
                        name: initBaseData['user'].nickname,
                        menuList: profileMenu
                    }
                }
            })
        }).$mount('#app')
    }).catch(e => {
        console.log(e)
        clearAuthorization()
        location.href = ERROR_PAGE
    })


})()


function organizationListToTree(list) {
    const map = {}
    let node
    const roots = []

    for (let i = 0; i < list.length; i += 1) {
        map[list[i].id] = i // initialize the map
        if (list[i].dataType !== 'E') {
            list[i].children = [] // initialize the children
        }
    }

    for (let i = 0; i < list.length; i += 1) {
        node = list[i]
        const item = {
            id: `${node.dataType}_${(node.data.id || node.id)}`,
            label: node.dataType !== 'E' ? node.text : `${node.text} (${node.data.nickname})`,
            children: node.children,
            type: node.dataType,
            externalCode: node.data.externalCode,
        }

        if (node.parent !== '#') {
            // if you have dangling branches check that map[node.parentId] exists
            list[map[node.parent]].children.push(item)
        } else {
            roots.push(item)
        }
    }

    return roots
}
