<template>
  <v-card outlined>
    <template v-for="(item, index) in list">
      <v-card-subtitle>{{ item.title }}</v-card-subtitle>
      <v-card-text :key="index">
        <div style="white-space: pre-wrap">{{ item.contents }}</div>
      </v-card-text>
      <v-card-text class="text-caption text-right">{{ item.date }}</v-card-text>
      <v-divider v-if="noticeList.length-1 != index"></v-divider>
    </template>
  </v-card>
</template>
<script>
export default {
  name: 'mp-notice',
  props: {
    /*contents: String,
    date: String*/
    noticeList: Array
  },
  computed: {
    list() {
      return [].concat(this.noticeList).reverse();
    }
  }
}
</script>