<template>
  <v-overlay :value="getOverlay" :z-index="999999999">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>
<script>

import {createNamespacedHelpers} from "vuex";

const baseHelper = createNamespacedHelpers('base');

export default {
  name: 'mp-overlay',
  created() {

  },
  computed: {
    ...baseHelper.mapGetters([
      'getOverlay'
    ])
  }
}
</script>
