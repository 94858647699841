import {isNumber, omit} from 'lodash'
import deepmerge from 'deepmerge'

export default {
    namespaced: true,
    state: {
        creatives: {},
        editAdItem: {id: null},
        editAudienceType: null,
        ticketValues: {
            campaign: {},
            campaignAds: []
        },
        platformMetaOptions: {},
        adItemFormValid: false,
        platformApplyResponse: {},
        editAdItemDialogWidth: '1400',
        googleAdsResourceMap: {},
        facebook: {
            sdk: null,
            accessToken: null,
            businessId: null
        },
        managedDialogs: [],
        updatedStructureGroups: false,
        copiedValue: {}
    },
    getters: {
        getCreatives: state => state.creatives,
        getEditAdItem: state => state.editAdItem,
        getTicketValues: state => state.ticketValues,
        getPlatformMetaOptions: state => state.platformMetaOptions,
        getAdItemFormValid: state => state.adItemFormValid,
        getPlatformApplyResponse: state => state.platformApplyResponse,
        getEditAdItemDialogWidth: state => state.editAdItemDialogWidth,
        getGoogleAdsResourceMap: state => state.googleAdsResourceMap,
        getFacebookSdk: state => state.facebook.sdk,
        getFacebookAccessToken: state => state.facebook.accessToken,
        getFacebookBusinessId: state => state.facebook.businessId,
        getEditAudienceType: state => state.editAudienceType,
        getManagedDialogs: state => state.managedDialogs,
        getUpdatedStructureGroups: state => state.updatedStructureGroups,
        getCopiedValue: state => state.copiedValue

    },
    mutations: {
        setEditAdItemDialogWidth: (state, editAdItemDialogWidth) => {
            state.editAdItemDialogWidth = isNumber(editAdItemDialogWidth) ? String(editAdItemDialogWidth) : editAdItemDialogWidth
        },
        setAdItemFormValid: (state, adItemFormValid) => {
            state.adItemFormValid = adItemFormValid
        },
        setCreatives: (state, creatives) => {
            state.creatives = creatives
        },
        setEditAdItem: (state, editAdItem) => {
            state.editAdItem = editAdItem
        },
        setEditAdItemUpdateRaw: (state, updateRaw) => {
            //state.editAdItem.updateRaw = merge(state.editAdItem.updateRaw, updateRaw);
            const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray
            state.editAdItem.updateRaw = deepmerge(state.editAdItem.updateRaw, updateRaw, {arrayMerge: overwriteMerge})
        },
        removeUpdateRawByKey: (state, key) => {
            state.editAdItem.updateRaw = omit(state.editAdItem.updateRaw, key)
        },
        setTicketValues: (state, ticketValues) => {
            state.ticketValues = ticketValues
        },
        setPlatformMetaOptions: (state, obj) => {
            state.platformMetaOptions[obj.key] = obj.options
        },
        setPlatformApplyResponse: (state, response) => {
            state.platformApplyResponse = response
        },
        addGoogleAdsResourceMap: (state, map) => {
            state.googleAdsResourceMap = Object.assign(state.googleAdsResourceMap, map)
        },
        setFacebookSdk: (state, sdk) => {
            state.facebook.sdk = sdk
        },
        setFacebookAccessToken: (state, accessToken) => {
            state.facebook.accessToken = accessToken
        },
        setFacebookBusinessId: (state, businessId) => {
            state.facebook.businessId = businessId
        },
        setEditAudienceType: (state, editAudienceType) => {
            state.editAudienceType = editAudienceType
        },
        addManagedDialogs: (state, dialogId) => {
            if (!state.managedDialogs.map(e => e.ref).includes(dialogId)) {
                state.managedDialogs.push({ref: dialogId, right: 0, top: null})
            }
        },
        removeManagedDialogs: (state, dialogId) => {
            if (state.managedDialogs.map(e => e.ref).includes(dialogId)) {
                state.managedDialogs.splice(state.managedDialogs.map(e => e.ref).indexOf(dialogId), 1)
            }
        },
        setMinimizeDialogPosition: (state, dialog) => {
            const target = state.managedDialogs.find(e => e.ref == dialog.ref)
            target.right = dialog.right
            target.top = dialog.top
        },
        clearManagedDialogs: (state) => {
            state.managedDialogs = []
        },
        setUpdatedStructureGroups: (state, updatedStructureGroups) => {
            state.updatedStructureGroups = updatedStructureGroups
        },
        setCopiedValue: (state, copiedValue) => {
            state.copiedValue = copiedValue
        },
    },
    actions: {
        setEditAdItemDialogWidth: ({commit}, editAdItemDialogWidth) => {
            commit('setEditAdItemDialogWidth', editAdItemDialogWidth)
        },
        setAdItemFormValid({commit}, adItemFormValid) {
            commit('setAdItemFormValid', adItemFormValid)
        },
        setCreatives({commit}, creatives) {
            commit('setCreatives', creatives)
        },
        setEditAdItem({commit}, editAdItem) {
            commit('setEditAdItem', editAdItem)
        },
        setEditAdItemUpdateRaw({commit}, updateRaw) {
            commit('setEditAdItemUpdateRaw', updateRaw)
        },
        removeUpdateRawByKey({commit}, key) {
            commit('removeUpdateRawByKey', key)
        },
        setTicketValues({commit}, ticketValues) {
            commit('setTicketValues', ticketValues)
        },
        setPlatformMetaOptions({commit}, obj) {
            commit('setPlatformMetaOptions', obj)
        },
        setPlatformApplyResponse({commit}, response) {
            commit('setPlatformApplyResponse', response)
        },
        addGoogleAdsResourceMap({commit}, map) {
            commit('addGoogleAdsResourceMap', map)
        },
        setFacebookSdk({commit}, sdk) {
            commit('setFacebookSdk', sdk)
        },
        setFacebookAccessToken({commit}, accessToken) {
            commit('setFacebookAccessToken', accessToken)
        },
        setFacebookBusinessId({commit}, businessId) {
            commit('setFacebookBusinessId', businessId)
        },
        setEditAudienceType({commit}, group) {

            const positionMap = [
                'W', 'P', 'S', 'C', 'D'
            ]

            const position = group.position
            const span = group.span
            const result = []
            for (let i = position; i < (position + span); i++) {
                result.push(positionMap[i])
            }

            commit('setEditAudienceType', result.join(''))
        },
        addManagedDialogs({commit}, dialogId) {
            commit('addManagedDialogs', dialogId)
        },
        removeManagedDialogs({commit}, dialogId) {
            commit('removeManagedDialogs', dialogId)
        },
        clearManagedDialogs({commit}) {
            commit('clearManagedDialogs')
        },
        setMinimizeDialogPosition({commit}, dialog) {
            commit('setMinimizeDialogPosition', dialog)
        },
        setUpdatedStructureGroups({commit}, updatedStructureGroups) {
            commit('setUpdatedStructureGroups', updatedStructureGroups)
        },
        setCopiedValue({commit}, copiedValue) {
            commit('setCopiedValue', copiedValue)
        },

    }
}
