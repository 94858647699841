<template>
  <div id="app" v-cloak>
    <div class="ubcs-container">
      <v-app>
        <mp-app-bar :logo="logo" :profile="profile" @click:nav-icon="drawer = !drawer"></mp-app-bar>
        <mp-navigation-drawer v-if="!hideNavigation" :logo="logo" :service-menu="templateMenus" :width="190"
                              v-model="drawer"></mp-navigation-drawer>
        <v-main>
          <v-container fluid>
            <v-fade-transition hide-on-leave>
              <router-view></router-view>
            </v-fade-transition>
          </v-container>
        </v-main>

        <mp-overlay></mp-overlay>
        <mp-dialog :dialog="dialogs.notice"></mp-dialog>
        <mp-pnl-close-notice></mp-pnl-close-notice>
      </v-app>
    </div>
  </div>
</template>

<script>
import MpAppBar from '@common/components/navigation/MpAppBar'
import MpNavigationDrawer from '@common/components/navigation/MpNavigationDrawer'
import MpOverlay from '@common/components/ui/MpOverlay'
import {createNamespacedHelpers} from 'vuex'
import {subscribeGlobalMessage, subscribeUserMessage} from '@/websocket/ticket'
import MpNotice from '@/component/common/MpNotice'
import MpPnlCloseNotice from "@/component/common/MpPnlCloseNotice.vue";

const baseHelper = createNamespacedHelpers('base')

export default {
  name: 'App',
  components: {
    MpPnlCloseNotice,
    MpAppBar,
    MpNavigationDrawer,
    MpOverlay
  },
  props: {
    logo: Object,
    templateMenus: Object,
    profile: Object
  },
  data: function () {
    return {
      drawer: null,
      noticeList: [],
      dialogs: {
        notice: {
          component: MpNotice,
          props: {noticeList: []},
          title: '공지사항',
          visible: false,
          width: '500px',
          close: () => {
            this.dialogs.notice.visible = false
            this.dialogs.notice.props.noticeList = []
          }
        }
      }
    }
  },
  computed: {
    ...baseHelper.mapGetters([
      'getUser'
    ]),
    hideNavigation() {

      if(this.$route.path === '/') {
        return true
      }

      if (this.$route.meta && this.$route.meta.menu && this.$route.meta.menu.params) {
        return this.$route.meta.menu.params.navigation === 'false'
      }

      return false

    }
  },
  mounted() {

    subscribeGlobalMessage(data => {
      this.showNoticeDialog(data.from, data.title, data.contents, data.date)
    })

    subscribeUserMessage(this.getUser.id, (data) => {
      this.showNoticeDialog(data.from, data.title, data.contents, data.date)
    })

  },
  methods: {
    showNoticeDialog(from, title = '공지사항', contents, date) {

      if (from === this.getUser.id) {
        return
      }


      this.dialogs.notice.props.noticeList.push({
        title: title,
        contents: contents,
        date: date
      })

      this.dialogs.notice.visible = true
    }
  }
}
</script>

<style src="@assets/scss/mercury.vuetify.scss" lang="scss"></style>
<style src="@assets/scss/mercury.template.scss" lang="scss"></style>
<style src="@assets/scss/mercury.common.scss" lang="scss"></style>
<style src="@assets/scss/mercury.discovery.scss" lang="scss"></style>
<style>
html {
  overflow-x: auto !important;
}
</style>
