import {confirm, notify, xAjax} from '@plugins/MercuryBaseLib'
import moment from "moment";

const YOUTUBE_API_KEY = process.env.VUE_APP_YOUTUBE_API_KEY

export const getFileServletUrl = (file) => {

    const userFileName = file.userFileName

    if (userFileName.includes('.jpg') || userFileName.includes('.jpeg')
        || userFileName.includes('.png') || userFileName.includes('.gif')
        || userFileName.includes('.bmp') || userFileName.includes('.webp')) {

        return file.url
    }

    return `${API_HOST}/file/download/${file.fileKey}`


}

export const setMyApproves = async ($store) => {
    const resp = await xAjax({
        url: '/approve/myApproves'
    })

    await $store.dispatch('base/setMyApproveList', resp)
}

export const setCountTicketCount = async ($store) => {

    const resp = await xAjax({
        url: '/campaign/getTicketBadgeCount'
    })

    const assignedTicketCount = resp.assignedTicketCount

    const writingTicketCount = resp.writingTicketCount

    const pendingTicketCount = resp.pendingTicketCount || 0

    const prevAssignedTicketCount = $store.state.base.badge.assignedTicketCount
    //const prevWritingTicketCount = $store.state.base.badge.writingTicketCount;

    if (prevAssignedTicketCount != null) {
        if (assignedTicketCount > prevAssignedTicketCount) {
            //notify('신규 캠페인티켓 요청', {}, {type: 'info'});
        }
    }

    /*if (prevWritingTicketCount != null) {
        if (writingTicketCount > prevWritingTicketCount) {
            notify('신규 캠페인티켓 요청', {}, {type: 'info'});
        }
    }*/

    const pendingContractCount = resp.pendingContractCount || 0

    await $store.dispatch('base/setBadge', {
        assignedTicketCount: assignedTicketCount,
        writingTicketCount: writingTicketCount,
        pendingTicketCount: pendingTicketCount,
        pendingContractCount: pendingContractCount,
        totalTicketCount: assignedTicketCount + writingTicketCount
    })
}

export const searchYoutubeVideos = async (keyword = '', pageToken = null) => {

    const param = {maxResults: 50, key: YOUTUBE_API_KEY, part: 'snippet', type: 'video'}

    if (keyword) {
        param.q = keyword
    }

    if (pageToken) {
        param.pageToken = pageToken
    }

    const resp = await xAjax({
        usePrefixUrl: false,
        method: 'GET',
        url: 'https://www.googleapis.com/youtube/v3/search',
        data: param
    })

    return resp
}

export const getYoutubeVideoInfo = async (id) => {
    return await xAjax({
        usePrefixUrl: false,
        method: 'GET',
        url: 'https://www.googleapis.com/youtube/v3/videos',
        data: {
            id: id,
            part: 'snippet,contentDetails,statistics,status',
            key: YOUTUBE_API_KEY
        }
    })
}

export const getAppVersion = ($store) => {

    const notifyWarning = (refreshTime) => {
        const diff = refreshTime.diff(moment(), 'seconds')
        if (diff > 0) {
            notify(`캡슐이 새로운 버전으로 업데이트 되었습니다. ${diff} 초 뒤에 페이지 새로고침 됩니다.`, {'timeOut': "5000"}, {type: 'warning'})
            window.setTimeout(() => {
                notifyWarning(refreshTime)
            }, 5000)
        } else {
            window.location.reload()
        }

    }

    const currentAppInfo = $store.getters['base/getAppInfo']
    const timeout = 1000 * 60 * 10
    xAjax({
        usePrefixUrl: false,
        url: '/version.json'
    }).then(resp => {
        console.log('version', resp)
        if (currentAppInfo.version === null) {
            $store.dispatch('base/setAppInfo', resp)
            window.setTimeout(() => {getAppVersion($store)}, timeout)
            return
        }

        if (currentAppInfo.version !== resp.version || currentAppInfo.hash !== resp.hash) {
            notifyWarning(moment().add(1, 'minute'))
        }else {
            window.setTimeout(() => {getAppVersion($store)}, timeout)
        }
    })
}
