//https://kazupon.github.io/vue-i18n/started.html#html
//https://developerjournal.tistory.com/10

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en.json'
import ko from './ko.json'

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {en: en, ko: ko}
})
