<template>
  <div>
    <v-data-table dense fixed-header :height="$settings.datatable.rows10" :footer-props="$settings.datatable.footer10"
                  :headers="tbl.headers" :items="items">

      <template v-slot:item.targetType="{ item }">
        {{item.targetType}} [{{item.targetId}}]
      </template>

      <template v-slot:item.requestComment="{ item }">
        <div class="ellipsis">
          <a class="ellipsis" :title="item.requestComment"
             @click="link(item.pageLink)">{{ item.requestComment || '-----' }}</a>
        </div>
      </template>

    </v-data-table>
  </div>
</template>
<script>
import {createNamespacedHelpers} from "vuex";

const baseHelper = createNamespacedHelpers('base');
export default {
  name: 'mp-my-approve-list',
  data: function () {
    return {
      tbl: {
        headers: [
          {text: '대상', value: 'targetType', width: 150, sortable: true},
          {text: '요청자', value: 'requestUser', width: 150, sortable: true},
          {text: '요청일', value: 'requestDate', width: 150, sortable: true},
          {text: '요청 메세지', value: 'requestComment', sortable: false}
        ],
        paging: 'client'
      },
    }
  },
  computed: {
    ...baseHelper.mapGetters([
      'getMyApproveList',
    ]),
    items() {
      return this.getMyApproveList.map(e => {

        const requestItem = e.items.find(item => item.approveStatus === 'REQUEST');

        return {
          targetType: e.targetType,
          targetId: e.targetId,
          pageLink: e.pageLink,
          requestUser: requestItem.user.displayName,
          requestDate: requestItem.updatedAt,
          requestComment: requestItem.comment
        }
      })
    }
  },
  methods: {
    link(pageLink) {
      if (!pageLink) {
        this.mercury.base.lib.notify({message: '바로가기 링크가 존재하지 않습니다.', type: 'warning'})
      }
      window.open(pageLink, '_blank')
    }
  }
}
</script>