<template>
  <v-list-group v-if="menu.id" v-model="isActive" :group="group" v-bind="$attrs" prepend-icon="" append-icon="">
    <template #activator>
      <v-list-item dense>
        <v-list-item-content>

          <template v-if="menu.params && menu.params.badge">
            <v-badge
                inline
                color="red"
                :value="getBadge[menu.params.badge]"
                :content="getBadge[menu.params.badge]"
            >
              <v-list-item-title v-if="menu.name" v-text="menu.name"/>
            </v-badge>
          </template>
          <template v-else>
            <v-list-item-title v-if="menu.name" v-text="menu.name"/>
          </template>

        </v-list-item-content>
      </v-list-item>
      <v-icon v-if="isActive">mdi-chevron-up</v-icon>
      <v-icon v-else>mdi-chevron-down</v-icon>
    </template>

    <template v-for="childMenu in menu.children" v-if="childMenu.params.hidden != 'true' && canAccess(childMenu.roles)">
      <mp-menu-group v-if="childMenu.children.length > 0" :key="childMenu.id" :menu="childMenu" :level="level + 1"
                     sub-group/>

      <v-list-item v-else :key="childMenu.id" :to="childMenu.path" tag="a" dense>
        <template v-if="childMenu.params && childMenu.params.badge">
          <v-badge
              inline
              color="red"
              :value="getBadge[childMenu.params.badge]"
              :content="getBadge[childMenu.params.badge]"
          >
            <v-list-item-title>{{ childMenu.name }}</v-list-item-title>
          </v-badge>
        </template>
        <template v-else>
          <v-list-item-title>{{ childMenu.name }}</v-list-item-title>
        </template>
      </v-list-item>
    </template>
  </v-list-group>
</template>
<script>

import {createNamespacedHelpers} from 'vuex';

const baseHelper = createNamespacedHelpers('base');

export default {
  name: 'mp-menu-group',
  props: {
    menu: {
      type: Object,
      default: () => ({})
    },
    level: {
      type: Number,
      default: 0
    }
  },
  data: () => ({isActive: null}),
  computed: {
    group() {
      return this.genGroup(this.menu.children);
    },
    ...baseHelper.mapGetters([
      'getBadge',
      'getUser'
    ])
  },
  methods: {
    genGroup(menuChildren) {
      return menuChildren.reduce((acc, cur) => {
        acc.push(cur.children && cur.children.length > 0 ? this.genGroup(cur.children) : cur.path);
        return acc;
      }, []).join('|');
    },
    canAccess(menuRoles) {
      let canAccess = true;
      if (menuRoles) {
        canAccess = false;
        menuRoles.forEach(mr => {
          if (this.getUser.roles.includes(mr)) {
            canAccess = true
          }
        })
      }
      return canAccess;
    }
  }
}
</script>
