<template>
  <div class="ubcs-app-bar">
    <v-app-bar app flat dense>
      <v-app-bar-nav-icon class="hidden-lg-and-up" @click="$emit('click:nav-icon')" aria-label="menu"/>
      <a v-if="$vuetify.breakpoint.smOnly || $vuetify.breakpoint.mdOnly" href="/" class="d-flex align-center app-logo">
        <v-img v-if="logo.src" :src="logo.src" :alt="logo.title" class="shrink" :width="logo.width"
               :height="logo.height"/>
      </a>
      <span class="ml-2 white--text">{{ profile.env !== 'PROD' ? profile.env : '' }}</span>
      <v-spacer></v-spacer>
      <v-menu v-if="profile && profile.name" offset-y :nudge-width="45">
        <template v-slot:activator="{ on, attrs }">

          <v-btn text tile v-bind="attrs" v-on="on">
            <v-icon left>
              mdi-account
            </v-icon>
            <v-badge
                inline
                color="red"
                left
                :value="getAppBarBadgeCount"
                :content="getAppBarBadgeCount"
            >
              {{ profile.name }}

            </v-badge>
            <v-icon right>
              mdi-chevron-down
            </v-icon>
          </v-btn>



        </template>
        <v-list nav dense>
          <v-list-item-group>
            <v-list-item v-for="(menu, index) in profile.menuList" :key="index" dense :href="menu.href"
                         @click="menu.click">
              <v-icon left dense>{{ menu.icon }} mdi-18px</v-icon>
              <v-list-item-content>
                <v-list-item-title>{{ menu.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <template v-if="this.hasAnyRole(['MERCURY'])">
              <!--     결재 리스트       -->
              <v-list-item dense @click="dialog.approveList.visible=true">
                <v-icon left dense>mdi-clipboard-text-outline mdi-18px</v-icon>
                <v-badge
                    inline
                    color="red"
                    left
                    :value="getMyApproveList.length"
                    :content="getMyApproveList.length"
                >
                  <v-list-item-content style="padding-left:4px;">
                    승인 대기 결재 목록
                  </v-list-item-content>
                </v-badge>
              </v-list-item>
              <!-- 가이드 파일 링크 -->
              <v-list-item dense @click="openGuideFolder">
                <v-icon left dense>mdi-help-circle-outline mdi-18px</v-icon>
                <v-list-item-content style="padding-left:4px;">
                  가이드 파일
                </v-list-item-content>
              </v-list-item>
            </template>
            <!-- 테마 -->
            <v-list-item dense>
              <v-icon left dense>mdi-theme-light-dark mdi-18px</v-icon>
              <v-list-item-content style="padding-left:4px;">
                <v-switch v-model="theme" color="black" hide-details inset class="theme-switch">
                  <template v-slot:label>
                    {{ themeLabel }}
                  </template>
                </v-switch>
              </v-list-item-content>
            </v-list-item>
            <!-- 언어 변경 -->
            <!--
            <v-list-item>
              <v-icon left dense>mdi-earth mdi-18px</v-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <v-chip class="mr-2" x-small label :color="locale !== 'ko'? 'green': 'gray'"
                          @click="changeLocale('en')">ENG
                  </v-chip>
                  <v-chip class="mr-2" x-small label :color="locale === 'ko'? 'green': 'gray'"
                          @click="changeLocale('ko')">KOR
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            -->
            <!-- 로그아웃 -->
            <v-list-item dense @click="logout">
              <v-icon left dense color="red">mdi-power mdi-18px</v-icon>
              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>

    <mp-dialog :dialog="dialog.approveList"></mp-dialog>

  </div>
</template>
<script>

import {createNamespacedHelpers} from 'vuex';
import {clearAuthorization, xAjaxJson} from "../../../plugins/MercuryBaseLib";
import {getCookie, COOKIE_NAME_LOCALE, setCookie} from "../../../plugins/MercuryBaseUtils";
import MpMyApproveList from "@common/components/ui/MpMyApproveList.vue";


const baseHelper = createNamespacedHelpers('base');

export default {
  name: 'mp-app-bar',
  props: {
    logo: {
      type: Object,
      default: () => ({})
    },
    profile: {
      type: Object,
      default: () => ({})
    }
  },
  data: function () {
    return {
      theme: false,
      themeLabel: 'Light',
      locale: 'en',
      dialog: {
        passwordChange: {
          visible: false,
        },
        approveList: {
          title: '승인 대기 결재 목록',
          width: '1000px',
          visible: false,
          component: MpMyApproveList,
          props: {},
          close: () => {
            this.dialog.approveList.visible = false;
          }
        }
      }
    };
  },
  computed: {
    ...baseHelper.mapGetters([
      'getUser',
      'getMyApproveList',
      'getAppBarBadgeCount'
    ])
  },
  watch: {
    theme: {
      handler(isDark) {
        this.$vuetify.theme.isDark = isDark;
        if (isDark) {
          setCookie('theme', 'black', 30);
          document.body.classList.add("ubcs-theme-dark");
          this.themeLabel = 'Dark';
        } else {
          setCookie('theme', null, 30);
          document.body.classList.remove("ubcs-theme-dark");
          this.themeLabel = 'Light';
        }
      }
    }
  },
  created() {
    if (getCookie('theme') === 'black') {
      this.$vuetify.theme.isDark = true;
      this.theme = true;
      this.themeLabel = 'Dark';
      document.body.classList.add("ubcs-theme-dark");
    }
    let locale = getCookie(COOKIE_NAME_LOCALE);
    if (!locale) {
      locale = 'ko';
      setCookie(COOKIE_NAME_LOCALE, locale, 30)
    }

    this.locale = locale
    this.$i18n.locale = locale
  },
  methods: {
    changeLocale: function (locale) {
      this.locale = locale
      this.$i18n.locale = locale
      setCookie(COOKIE_NAME_LOCALE, locale, 30)
    },
    logout: function () {
      clearAuthorization();
      location.href = '/login.html';
    },
    openGuideFolder(){
      window.open('https://drive.google.com/drive/u/0/folders/1LDG3GJDChKVNRV91YKNMmO3uENiuqR3H');
    }
  }
}

</script>
