<template>
  <v-card class="mp-active-user--wrapper">
    <v-subheader class="text-left header" @click="show = !show">
      <span class="primary--text mr-1">{{ list.length }}</span> 명의 사용자가 접속중
    </v-subheader>
    <v-expand-transition>
      <v-list dense height="300" class="scrollable" v-show="show">
        <v-list-item-group mandatory
            v-model="selectedUser"
        >
          <template v-for="(item, index) in list" >
            <v-divider></v-divider>
            <v-list-item class="pa-1" two-line :key="index">
              <v-list-item-icon style="margin-right: 0;">
                <v-icon small>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="pr-1">
                  {{ item.name }}({{ item.nickname }})
                </v-list-item-title>
                <v-list-item-subtitle class="text-right">{{ item.time }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-expand-transition>
  </v-card>
</template>
<script>
import {notify, xAjax} from "@plugins/MercuryBaseLib";
import capsuleBaseMixin from "@common/mixins/capsuleBaseMixin";
import {subscribeGlobalActiveUsers, unsubscribeGlobalActiveUsers} from "@/websocket/ticket";

export default {
  name: 'mp-active-user',
  mixins: [capsuleBaseMixin],
  data: function () {
    return {
      list: [],
      show: false,
      selectedUser: null
    }
  },
  created() {
    window.setTimeout(() => {
      this.fetch();
      subscribeGlobalActiveUsers((resp) => {
        this.setList(resp);
      })
    }, 1000);
    window.setInterval(() => {

      this.list = this.list.map(e => ({
        ...e,
        time: this.timeForToday(e.ts)
      }))

    }, 1000 * 60);
  },
  destroyed() {
    unsubscribeGlobalActiveUsers();
  },
  methods: {
    timeForToday(value) {
      if(value == 0) {
        return '비활성';
      }

      const today = new Date();
      const timeValue = new Date(value);

      const betweenTime = Math.floor((today.getTime() - timeValue.getTime()) / 1000 / 60);
      if (betweenTime < 1) return '방금 전';
      if (betweenTime < 60) {
        return `${betweenTime}분 전`;
      }

      const betweenTimeHour = Math.floor(betweenTime / 60);
      if (betweenTimeHour < 24) {
        return `${betweenTimeHour}시간 전`;
      }

      const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
      if (betweenTimeDay < 365) {
        return `${betweenTimeDay}일 전`;
      }

      return `${Math.floor(betweenTimeDay / 365)}년 전`;
    },
    setList(resp) {
      const _list = resp.map(e => {
        const ts = Number(e.split('-').pop());
        const time = this.timeForToday(ts);
        return {
          id: Number(e.substring(e.indexOf('(') + 1, e.indexOf(')'))),
          time: time,
          ts: ts
        }
      })

      this.list = _list.map(e => ({
        ...this.getActiveEmployees.find(_e => _e.id === e.id),
        time: e.time,
        ts: e.ts
      }))

    },
    async fetch() {
      const resp = await xAjax({
        url: '/admin/getActiveUsers'
      })

      this.setList(resp);
    }
  }
}
</script>
<style lang="scss" scoped>
.mp-active-user--wrapper {
  .header {
    cursor: pointer;
  }
}
.scrollable {
  overflow-y: auto;
}
</style>
