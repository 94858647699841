import {deepCopy} from "./MercuryBaseUtils";

const UNITS = {
  LEVEL: {
    CM: ['CAMPAIGN', 'PLACEMENT', 'AD', 'CREATIVE'],
    SA360: ['CAMPAIGN', 'AD_GROUP', 'AD'],

    DV360_DISPLAY: ['CAMPAIGN', 'INSERTION_ORDER', 'LINE_ITEM', 'CREATIVE'],
    DV360_VIDEO: ['INSERTION_ORDER', 'LINE_ITEM', 'AD_GROUP', 'AD'],
    GADS: ['CAMPAIGN', 'AD_GROUP', 'AD'],
    FACEBOOK: ['CAMPAIGN', 'AD_GROUP', 'AD'],
    TIKTOK: ['CAMPAIGN', 'AD_GROUP', 'AD'],
    TWITTER: ['CAMPAIGN', 'AD_GROUP', 'AD'],

    NAVER_GFA: ['CAMPAIGN', 'AD_GROUP', 'AD'],
    NAVER_SEARCH: ['CAMPAIGN', 'AD_GROUP', 'CREATIVE'],
    NAVER_BRAND: ['CAMPAIGN', 'AD_GROUP', 'AD', 'CREATIVE'],
    NAVER_SHOPPING: ['CAMPAIGN', 'AD_GROUP'],
    NAVER_NOSP: ['CAMPAIGN', 'AD'],

    KAKAO_MOMENT: ['CAMPAIGN', 'AD_GROUP', 'AD'],
    KAKAO_KEYWORD: ['CAMPAIGN', 'AD_GROUP'],
    KAKAO_BRAND: ['CAMPAIGN', 'AD_GROUP', 'AD', 'CREATIVE'],

    CRITEO: ['CAMPAIGN', 'AD_GROUP', 'AD'],
    SMR: ['CAMPAIGN', 'CREATIVE'],
    ZAPPLE: ['CAMPAIGN', 'AD_GROUP', 'AD'],
    BUZZVIL: ['CAMPAIGN'],
    BLIND: ['CAMPAIGN', 'CREATIVE'],
    APPLE_SEARCHADS: ['CAMPAIGN', 'AD_GROUP'],
    ACE_TRADER: ['CAMPAIGN', 'AD_GROUP', 'AD'],
    DABLE: ['CAMPAIGN', 'AD'],
    TABOOLA: ['CAMPAIGN', 'AD'],
    DIGITAL_CAMP: ['CAMPAIGN', 'CREATIVE'],
  },
}

export const GROUP = {
  custom: {
    columns: {
      CALC: [
        {side: 'DSP', platform: '', type: 'CALC', value: 'CPC', text: 'CPC'},
        {side: 'DSP', platform: '', type: 'CALC', value: 'CPM', text: 'CPM'},
        {side: 'DSP', platform: '', type: 'CALC', value: 'CPV', text: 'CPV'},
        {side: 'DSP', platform: '', type: 'CALC', value: 'VTR', text: 'VTR'},
      ]
    },
    fields: {
      CALC: [{value: 'value', text: 'value'}],
    },
    selected: []
  },
  dsp: {
    platforms: [],
    items: {},
    level: {
      SA360: UNITS['LEVEL']['SA360'],

      DV360_DISPLAY: UNITS['LEVEL']['DV360_DISPLAY'],
      DV360_VIDEO: UNITS['LEVEL']['DV360_VIDEO'],
      GADS: UNITS['LEVEL']['GADS'],
      FACEBOOK: UNITS['LEVEL']['FACEBOOK'],
      TIKTOK: UNITS['LEVEL']['TIKTOK'],
      TWITTER: UNITS['LEVEL']['TWITTER'],

      NAVER_GFA: UNITS['LEVEL']['NAVER_GFA'],
      NAVER_SEARCH: UNITS['LEVEL']['NAVER_SEARCH'],
      NAVER_BRAND: UNITS['LEVEL']['NAVER_BRAND'],
      NAVER_SHOPPING: UNITS['LEVEL']['NAVER_SHOPPING'],
      NAVER_NOSP: UNITS['LEVEL']['NAVER_NOSP'],

      KAKAO_MOMENT: UNITS['LEVEL']['KAKAO_MOMENT'],
      KAKAO_KEYWORD: UNITS['LEVEL']['KAKAO_KEYWORD'],
      KAKAO_BRAND: UNITS['LEVEL']['KAKAO_BRAND'],

      CRITEO: UNITS['LEVEL']['CRITEO'],
      SMR: UNITS['LEVEL']['SMR'],
      ZAPPLE: UNITS['LEVEL']['ZAPPLE'],
      BUZZVIL: UNITS['LEVEL']['BUZZVIL'],
      BLIND: UNITS['LEVEL']['BLIND'],
      APPLE_SEARCHADS: UNITS['LEVEL']['APPLE_SEARCHADS'],
      ACE_TRADER: UNITS['LEVEL']['ACE_TRADER'],
      DABLE: UNITS['LEVEL']['DABLE'],
      TABOOLA: UNITS['LEVEL']['TABOOLA'],
      DIGITAL_CAMP: UNITS['LEVEL']['DIGITAL_CAMP'],
    },
    columns: [],
    fields: {
      DV360_DISPLAY: [{value: 'value', text: 'value'}],
      DV360_VIDEO: [{value: 'value', text: 'value'}],
      GADS: [{value: 'value', text: 'value'}],
      FACEBOOK: [{value: 'value', text: 'value'}],
      TIKTOK: [{value: 'value', text: 'value'}],
      TWITTER: [{value: 'value', text: 'value'}],

      NAVER_GFA: [{value: 'value', text: 'value'}],
      NAVER_SEARCH: [{value: 'value', text: 'value'}],
      NAVER_BRAND: [{value: 'value', text: 'value'}],
      NAVER_SHOPPING: [{value: 'value', text: 'value'}],
      NAVER_NOSP: [{value: 'value', text: 'value'}],

      KAKAO_MOMENT: [{value: 'value', text: 'value'}],
      KAKAO_KEYWORD: [{value: 'value', text: 'value'}],
      KAKAO_BRAND: [{value: 'value', text: 'value'}],

      CRITEO: [{value: 'value', text: 'value'}],
      SMR: [{value: 'value', text: 'value'}],
      ZAPPLE: [{value: 'value', text: 'value'}],
      BUZZVIL: [{value: 'value', text: 'value'}],
      BLIND: [{value: 'value', text: 'value'}],
      APPLE_SEARCHADS: [{value: 'value', text: 'value'}],
      ACE_TRADER: [{value: 'value', text: 'value'}],
      DABLE: [{value: 'value', text: 'value'}],
      TABOOLA: [{value: 'value', text: 'value'}],
      DIGITAL_CAMP: [{value: 'value', text: 'value'}]
    }
  },
  conversion: {
    selected: [],
    platforms: [],
    items: {},
    fields: {
      DV360_DISPLAY: [
        {value: 'post_click_conversions', text: 'post_click_conversions'},
        {value: 'cm_post_click_revenue', text: 'cm_post_click_revenue'},
        {value: 'post_view_conversions', text: 'post_view_conversions'},
        {value: 'cm_post_view_revenue', text: 'cm_post_view_revenue'},
        {value: 'total_conversions', text: 'total_conversions'}
      ],
      DV360_VIDEO: [
        {value: 'conversions', text: 'conversions'},
        {value: 'view_through_conversion', text: 'view_through_conversion'},
        {value: 'total_conversion_value', text: 'total_conversion_value'},
      ],
      CM: [
        {value: 'click_through_conversions', text: 'click_through_conversions'},
        {value: 'click_through_revenue', text: 'click_through_revenue'},
        {value: 'view_through_conversions', text: 'view_through_conversions'},
        {value: 'view_through_revenue', text: 'view_through_revenue'},
        {value: 'total_conversions', text: 'total_conversions'},
        {value: 'total_revenue', text: 'total_revenue'}
      ],
      GA: [
        {value: 'value', text: 'value'}
      ],
      GA_EVENT: [
        {value: 'total_events', text: 'total_events'},
        {value: 'unique_events', text: 'unique_events'},
        {value: 'event_value', text: 'event_value'},
        // {value:'avg_event_value', text:'avg_event_value'},
        // {value:'sessions_with_event', text:'sessions_with_event'},
        // {value:'events_per_session_with_event', text:'events_per_session_with_event'},
      ],
      GA4: [
        {value: 'value', text: 'value'}
      ],
      GA4_EVENT: [
        {value: 'event_count', text: 'event_count'},
        {value: 'event_count_per_user', text: 'event_count_per_user'},
        {value: 'events_per_session', text: 'events_per_session'},
        {value: 'event_value', text: 'event_value'},
        // {value:'avg_event_value', text:'avg_event_value'},
        // {value:'sessions_with_event', text:'sessions_with_event'},
        // {value:'events_per_session_with_event', text:'events_per_session_with_event'},
      ],
      FACEBOOK: [
        {value: 'total', text: 'total'},
        {value: 'value', text: 'value'}
      ],
      GADS: [
        {value: 'conversions_value', text: 'conversions_value'},
        {value: 'conversions', text: 'conversions'},
        {value: 'view_through_conversions', text: 'view_through_conversions'},
        {value: 'all_conversions', text: 'all_conversions'},
        {value: 'all_conversions_value', text: 'all_conversions_value'},

      ],
      SA360: [{value: 'value', text: 'value'}],
      TIKTOK: [{value: 'value', text: 'value'}],
      TIKTOK_SELF: [{value: 'value', text: 'value'}],
      TWITTER: [
        {value: 'value', text: 'value'},
        {value: 'total', text: 'total'}
      ],

      NAVER_GFA: [{value: 'value', text: 'value'}],
      NAVER_GFA_SELF: [{value: 'value', text: 'value'}],
      NAVER_SEARCH: [{value: 'value', text: 'value'}],
      NAVER_SEARCH_SELF: [{value: 'value', text: 'value'}],
      NAVER_BRAND: [{value: 'value', text: 'value'}],
      NAVER_BRAND_SELF: [{value: 'value', text: 'value'}],
      NAVER_SHOPPING: [{value: 'value', text: 'value'}],
      NAVER_SHOPPING_SELF: [{value: 'value', text: 'value'}],
      NAVER_NOSP: [{value: 'value', text: 'value'}],
      NAVER_NOSP_SELF: [{value: 'value', text: 'value'}],

      KAKAO_MOMENT: [{value: 'value', text: 'value'}],
      KAKAO_MOMENT_SELF: [{value: 'value', text: 'value'}],
      KAKAO_KEYWORD: [{value: 'value', text: 'value'}],
      KAKAO_KEYWORD_SELF: [{value: 'value', text: 'value'}],
      KAKAO_BRAND: [{value: 'value', text: 'value'}],
      KAKAO_BRAND_SELF: [{value: 'value', text: 'value'}],

      CRITEO: [{value: 'value', text: 'value'}],
      CRITEO_SELF: [{value: 'value', text: 'value'}],
      SMR: [{value: 'value', text: 'value'}],
      SMR_SELF: [{value: 'value', text: 'value'}],
      ZAPPLE: [{value: 'value', text: 'value'}],
      ZAPPLE_SELF: [{value: 'value', text: 'value'}],
      BUZZVIL: [{value: 'value', text: 'value'}],
      BUZZVIL_SELF: [{value: 'value', text: 'value'}],
      BLIND: [{value: 'value', text: 'value'}],
      BLIND_SELF: [{value: 'value', text: 'value'}],
      APPLE_SEARCHADS: [{value: 'value', text: 'value'}],
      APPLE_SEARCHADS_SELF: [{value: 'value', text: 'value'}],
      ACE_TRADER: [{value: 'value', text: 'value'}],
      ACE_TRADER_SELF: [{value: 'value', text: 'value'}],
      DABLE: [{value: 'value', text: 'value'}],
      DABLE_SELF: [{value: 'value', text: 'value'}],
      TABOOLA: [{value: 'value', text: 'value'}],
      TABOOLA_SELF: [{value: 'value', text: 'value'}],
      DIGITAL_CAMP: [{value: 'value', text: 'value'}],
      DIGITAL_CAMP_SELF: [{value: 'value', text: 'value'}]
    },
  }
}

export const getGroup = () => {
  return deepCopy(GROUP)
}

export const getUnitLevel = () => {
  return deepCopy(UNITS.LEVEL);
}

/**
 * @description 긴 platform 이름이 3자보다 큰 경우 3자로 substring 하여 리턴 한다.
 * 만약 3자 미만인 경우 원래의 값으로 반환 된다.
 *
 * FACEBOOK -> FAC, GA -> GA
 *
 * @param {String} platformName platform 이름
 * @return {String} 최대 3자의 짧아진 이름
 * */

export const getShortPlatformName = (platformName) => {
  if (platformName.length > 3) {
    return platformName.substring(0, 3);
  } else {
    return platformName;
  }
}

export const getPurposeColor = (purpose) => {
  if (purpose === 'OPERATION') {
    return 'green'
  } else if (purpose === 'DATA_STUDIO') {
    return 'info'
  }
  return 'grey'
}


export const getAdItemUnitMeta = (item) => {
  if (item.type === 'CAMPAIGN') {
    return {name: 'C', color: 'green'}
  } else if (item.type === 'AD_GROUP') {
    return {name: 'G', color: 'blue'}
  } else if (item.type === 'AD') {
    return {name: 'A', color: 'pink'}
  } else if (item.type === 'KEYWORD') {
    return {name: 'K', color: 'brown'}
  } else if (item.type === 'INSERTION_ORDER') {
    return {name: 'I', color: 'indigo'}
  } else if (item.type === 'LINE_ITEM') {
    return {name: 'L', color: 'deep-purple'}
  } else if (item.type === 'PLACEMENT') {
    return {name: 'P', color: 'blue'}
  } else if (item.type === 'CREATIVE') {
    return {name: 'C', color: 'pink'}
  } else {
    return {name: item.type.substring(0, 1), color: 'pink'}
  }
}

export const getAdItemStatusMeta = (status) => {
  if(status === 'ACTIVE') {
    return {name:'ACTIVE', color:'green', icon: 'mdi-play-circle mdi-18px'}
  }else if(status === 'PAUSED') {
    return {name:'PAUSED', color:'blue', icon: 'mdi-pause-circle mdi-18px'}
  }else if(status === 'REMOVED') {
    return {name:'REMOVED', color:'gray', icon: 'mdi-close-circle mdi-18px'}
  }else if(status === 'UNKNOWN') {
    return {name:'UNKNOWN', color:'brown', icon: 'mdi-alert-circle mdi-18px'}
  }

  return {name:'K', color:'brown', icon: 'mdi-crosshairs-question mdi-18px'}
}

export const getParameter = (url) => {
  const getUtmMap = function(url){
    const map = {}
    if(url){
      const regex = /(?=utm_)([^&]*)(?=&)?/g;
      const found = url.match(regex);
      if(found !== null){
        found.forEach(p=>{
          const sp = p.split('=')
          if(sp.length === 2){
            map[sp[0].toLowerCase()] = sp[1]
          }
        })
      }
    }

    return map;
  }

  const getCmMap = function(url){
    const map = {}
    const urlPrefix = 'https://ad.doubleclick.net/ddm/'
    if(url && url.startsWith(urlPrefix)){
      const inner = {}

      let newUrl = url.substring(urlPrefix.length)
      let sp = {}
      let functionKey = null;

      if(newUrl.startsWith('socialclk')){
        functionKey = "socialclk";
        newUrl = newUrl.substring(functionKey.length + 1);  // ? 이후 제거
        sp = newUrl.split("&");
      }else{
        const firstSlashIndex = newUrl.indexOf('/');
        functionKey = newUrl.substring(0, firstSlashIndex);
        newUrl = newUrl.substring(firstSlashIndex + 1);
        sp = newUrl.split(";");
      }

      if ("clk" === functionKey) {
        const spN = newUrl.split("%3f");
        if (spN.length === 2) {
          Object.assign(map, getCmMap(spN[1]));
        }

        const sp0 = spN[0].split(";");
        map['ds_ad_id'] = sp0[0];
        map['ds_placement_id'] = sp0[1];
      }else if('trackclk' === functionKey){
        sp.forEach(keyValue=>{
          const kv = keyValue.split("=");
          if (kv.length === 2) {
            inner[kv[0]] = kv[1]
          }
        })

        map['cm_ad_id'] = inner['dc_trk_aid']
        map['cm_creative_id'] = inner['dc_trk_cid']
        const campla = sp[0].substring(sp[0].indexOf("/B") + 2).split(".")
        map['cm_campaign_id'] = campla[0]
        if (campla.length === 2) {
          map['cm_placement_id'] = campla[1]
        }
      }
    }
    return map
  }

  const getAllParam = function(url){
    const map = {}
    try{
      if(url){
        const firstIndex = url.indexOf('?')
        if(firstIndex > -1){
          let queryString = url.substring(firstIndex+1);
          if(queryString){
            const secondIndex = queryString.indexOf('?');//CM URL은 Landing Page에 또 ? 가 있다.
            if(secondIndex > -1){
              queryString = queryString.substring(secondIndex+1);
            }

            queryString = queryString.split('#')[0];
            const paramPair = queryString.split('&');
            paramPair.forEach(pair=>{
              const keyVal = pair.split("=");
              map[keyVal[0]] = keyVal[1]
            });
          }
        }
      }
    }catch(e){
      console.log('extract parameter fail from url:'+url, e);
    }
    return map;
  }

  const utmMap = getUtmMap(url);
  const cmMap = getCmMap(url);
  const allMap = getAllParam(url);
  const etcMap = {}

  for(let all in allMap){
    if(utmMap[all] === undefined && cmMap[all] === undefined){
      etcMap[all] = allMap[all]
    }
  }

  return {
    utm: utmMap,
    cm: cmMap,
    etc: etcMap
  }
}